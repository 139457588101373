import {Component, OnInit} from '@angular/core';
import {UserService} from '../../../services/rest/user.service';
import {MessageService} from '../../../services/message.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UserCreate} from '../../../dtos/user/UserCreate';
import {AuthenticationService} from '../../../services/rest/authentication.service';
import {InputPasswordComponent} from '../../core/input/input-password/input-password.component';
import {SelectItem} from '../../../dtos/SelectItem';
import {PermissionService} from '../../../services/rest/permission.service';
import {GroupService} from '../../../services/rest/group.service';

@Component({
  selector: 'app-user-create',
  templateUrl: './user-create.component.html',
  styleUrls: ['./user-create.component.scss']
})
export class UserCreateComponent implements OnInit {

  form: FormGroup;

  allPermissions: SelectItem[] = [];
  allGroups: SelectItem[] = [];

  constructor(private formBuilder: FormBuilder,
              private authService: AuthenticationService,
              private route: ActivatedRoute,
              private router: Router,
              private userService: UserService,
              private permissionService: PermissionService,
              private groupService: GroupService,
              private messageService: MessageService) {
  }

  ngOnInit(): void {
    this.createForm();
    this.getGroups();
    this.getPermissions();
  }

  submitChanges(): void {
    if (this.form.valid) {
      const user: UserCreate = {
        email: this.form.controls.email.value,
        firstName: this.form.controls.firstName.value,
        lastName: this.form.controls.lastName.value,
        title: this.form.controls.title.value,
        locked: this.form.controls.locked.value,
        password: this.form.controls.password.value,
        imageId: this.form.controls.image.value?.id,
        permissionIds: this.form.controls.permissions.value,
        groupIds: this.form.controls.groups.value
      };
      this.userService.createUser(user).subscribe(respUser => {
        console.log('Redirect to /users/' + ' with query params email=' + respUser.email);
        this.router.navigate(['/users'], {queryParams: {email: respUser.email}});
        this.messageService.addSuccessMessage('Benutzer erfolgreich erstellt!');
      });
    }
  }

  private createForm() {
    this.form = this.formBuilder.group({
      firstName: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(255)]],
      lastName: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(255)]],
      title: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(255)]],
      email: ['', [Validators.required, Validators.email, Validators.maxLength(255)]],
      password: ['', [
        Validators.required,
        InputPasswordComponent.minOneLowerCaseCharacter(),
        InputPasswordComponent.minOneUpperCaseCharacter(),
        InputPasswordComponent.minOneNumberCaseCharacter(),
        Validators.minLength(8),
        Validators.maxLength(255)]],
      confirmPassword: ['', [InputPasswordComponent.matchValues('password'), Validators.required]],
      locked: false,
      image: [],
      permissions: [[]],
      groups: [[]]
    });
  }

  private getPermissions() {
    this.permissionService.getAllPermissionsAsSelectItems()
      .subscribe(permissions => this.allPermissions = permissions);
  }

  private getGroups() {
    this.groupService.getAllGroupsAsSelectItems().subscribe(groups => this.allGroups = groups);
  }

}
