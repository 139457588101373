import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Globals} from '../../global/globals';
import {Observable, of} from 'rxjs';
import {Sort} from '../../components/core/table/sort/Sort';
import {ProjectFilter} from '../../dtos/project/ProjectFilter';
import {PagedProject} from '../../dtos/project/PagedProject';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  pageMinDelay: number = this.globals.pageMinDelay;

  private fileBaseUrl: string = this.globals.backendUri + '/projects';

  constructor(private http: HttpClient,
              private globals: Globals) {
  }

  /**
   * Loads all projects from the backend
   */
  getProjects(projectFilter: ProjectFilter): Observable<PagedProject> {
    console.log('getFiles', JSON.stringify(projectFilter));
    if (!projectFilter) {
      console.log('FileFilter must be defined');
      return new Observable<PagedProject>();
    }
    if (projectFilter.page === null || projectFilter.page === undefined || projectFilter.page < 0) {
      console.log('Invalid page value ' + projectFilter.page);
      return new Observable<PagedProject>();
    }
    if (!projectFilter.size || projectFilter.size < 1) {
      console.log('Invalid page size ' + projectFilter.size);
      return new Observable<PagedProject>();
    }

    let params = new HttpParams();
    params = params.set('size', projectFilter.size.toString());
    params = params.set('page', projectFilter.page.toString());
    params = params.set('sortList', Sort.mapList(projectFilter.sortList));
    if (projectFilter.name) {
      params = params.set('name', projectFilter.name);
    }

    const m1: any = {
      id: 1,
      type: 'PV Anlage',
      name: 'XYZ-123',
      booked: 10,
      assigned: 10,
      bookedCosts: 10000,
      assignedCosts: 10000
    };
    const m2: any = {
      id: 1,
      type: 'Aluminiumkonstruktion',
      name: '180x80',
      booked: 6,
      assigned: 5,
      bookedCosts: 600,
      assignedCosts: 500
    };
    const m3: any = {
      id: 1,
      type: 'Divers',
      name: 'Schrauben',
      booked: 1,
      assigned: 0,
      bookedCosts: 50,
      assignedCosts: 0
    };

    const e1: any = {
      id: 1,
      firstname: 'Max',
      lastname: 'Mustermann',
      position: 'Elektromeister',
      booked: 10,
      assigned: 20,
      bookedCosts: 100,
      assignedCosts: 200
    };
    const e2: any = {
      id: 1,
      firstname: 'Anna',
      lastname: 'Schmidt',
      position: 'Elektromeister',
      booked: 0,
      assigned: 10,
      bookedCosts: 0,
      assignedCosts: 100
    };
    const e3: any = {
      id: 1,
      firstname: 'Lukas',
      lastname: 'Bauer',
      position: 'Betriebselektriker',
      booked: 30,
      assigned: 25,
      bookedCosts: 600,
      assignedCosts: 500
    };
    const e4: any = {
      id: 1,
      firstname: 'Tobias',
      lastname: 'Wolf',
      position: 'Lehrling',
      booked: 10,
      assigned: 10,
      bookedCosts: 50,
      assignedCosts: 50
    };
    const e5: any = {
      id: 1,
      firstname: 'Maria',
      lastname: 'Neumann',
      position: 'Kundenbetreuung',
      booked: 25,
      assigned: 0,
      bookedCosts: 750,
      assignedCosts: 0
    };

    const p1: any = {
      id: 4,
      status: 'Laufend',
      endDate: new Date(new Date().setMonth(5)).setDate(24),
      location: 'Wien',
      clientname: 'Katharina Fuchs',
      privateClient: false,
      name: 'Lieferung und Installation Heimkino',
      description: 'Description 1',
      employees: [e1, e2, e3, e4, e5],
      material: [m1, m2, m3],
      booked: 15,
      assigned: 20,
      bookedCosts: 150,
      assignedCosts: 200
    };
    const p2: any = {
      id: 3,
      status: 'In Vorbereitung',
      endDate: new Date().setDate(12),
      location: 'Wien',
      clientname: 'Hotel Sonnenschein AG',
      privateClient: false,
      name: 'Elektroinstallation Wohnhaus',
      description: 'Description 2',
      employees: [e1, e2, e3, e4, e5],
      material: [m1, m2, m3],
      booked: 10,
      assigned: 20,
      bookedCosts: 100,
      assignedCosts: 200
    };
    const p3: any = {
      id: 2,
      status: 'Laufend',
      endDate: new Date().setDate(new Date().getDate() - 2),
      location: 'Graz',
      clientname: 'Alexander Schmidt',
      privateClient: true,
      name: 'Lieferung und Installation E-Herd',
      description: 'Description 3',
      employees: [e1, e2, e3, e4, e5],
      material: [m1, m2, m3],
      booked: 19,
      assigned: 20,
      bookedCosts: 190,
      assignedCosts: 200
    };
    const p4: any = {
      id: 1,
      status: 'Laufend',
      endDate: new Date().setDate(new Date().getDate() + 5),
      location: 'Wien',
      clientname: 'Firma Bauwerk GmbH',
      privateClient: true,
      name: 'Installation PV Anlage',
      description: 'Description 3',
      employees: [e1, e2, e3, e4, e5],
      material: [m1, m2, m3],
      booked: 30,
      assigned: 20,
      bookedCosts: 300,
      assignedCosts: 200
    };


    const p: PagedProject = {} as any;
    const list = [p4, p2, p3, p1];
    const fl = list.concat(list).concat(list);
    let i = fl.length + 1;
    p.values = [];
    for (let l of fl) {
      l = JSON.parse(JSON.stringify(l));
      l.id = i;
      l.endDate = new Date(l.endDate);
      i = i - 1;
      if (i < 0) continue;
      p.values.push(l)
    }
    return of(p);

    // return this.http.get<PagedFile>(this.fileBaseUrl, {params});
  }

}
