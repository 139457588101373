import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Group} from '../../../dtos/group/Group';
import {GroupService} from '../../../services/rest/group.service';
import {ConfirmService} from '../../../services/confirm.service';
import {MessageService} from '../../../services/message.service';
import {InputButtonIconType} from '../../core/button/button.utils';
import {GroupFilter} from '../../../dtos/group/GroupFilter';
import {PagedGroup} from '../../../dtos/group/PagedGroup';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {AuthUtils} from '../../../utils/AuthUtils';
import {PermissionTargetEnum} from '../../../enums/PermissionTargetEnum';
import {TablePaginationUtils} from '../../core/table/pagination/TablePaginationUtils';
import {TableSortExtension} from '../../core/table/sort/TableSortExtension';
import {ArrayUtils} from '../../../utils/ArrayUtils';
import {StringUtils} from '../../../utils/StringUtils';
import {delay} from 'rxjs';

@Component({
  selector: 'app-group-table',
  templateUrl: 'group-table.component.html',
  styleUrls: ['group-table.component.scss', '../../core/table.scss']
})
export class GroupTableComponent extends TableSortExtension implements OnInit, AfterViewInit {
  InputButtonIconType = InputButtonIconType;
  ArrayUtils = ArrayUtils;
  ADMIN_GROUP_NAME = 'Admin';

  groups: Group[] = [];

  selectedName: string = null;

  loading = true;
  first = 0;
  paginationSize: number = null;
  totalGroups: number;
  lastFilter: GroupFilter = this.createFilter();

  @ViewChild('tableParentDiv', {read: ElementRef, static: false}) tableParentDiv: ElementRef;
  userCanEdit: boolean = AuthUtils.canEdit(PermissionTargetEnum.GROUPS);

  constructor(private router: Router,
              private route: ActivatedRoute,
              private groupService: GroupService,
              private confirmService: ConfirmService,
              private messageService: MessageService) {
    super();
    this.setOnSortOrderChangedCallbackFunction(this.onSearch);
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => this.processParams(params));
  }

  ngAfterViewInit(): void {
    TablePaginationUtils.calculateNumberOfRowsForTableBasedOnParentDivSizeAndFetchData(this.tableParentDiv, this.onSearch.bind(this));
  }

  onCreateGroup() {
    console.log('Redirect to /groups/create');
    this.router.navigate(['/groups/create']);
  }

  onEditGroup(group: Group): void {
    console.log('Redirect to /groups/' + group.id);
    this.router.navigate(['/groups/' + group.id]);
  }

  onDeleteGroup(group: Group): void {
    this.confirmService.confirm(
      `Benutzer löschen`,
      `Wollen Sie die Gruppe ${group.name} wirklich löschen? <br>` +
      `Dieser Vorgang kann nicht rückgängig gemacht werden.`,
      () => {
        this.groupService.deleteGroupById(group.id).toPromise().then(() => {
          this.messageService.addSuccessMessage(`Gruppe ${group.name} wurde erfolgreich gelöscht!`);
          this.onSearch();
        });
      });
  }

  searchLazy(event: any) {
    if (!this.paginationSize) {
      return;
    }
    let firstRow = 0;
    if (event.first) {
      firstRow = event.first;
    }
    this.lastFilter.page = firstRow / this.paginationSize;
    this.lastFilter.size = this.paginationSize;
    this.searchGroups(this.lastFilter);
  }

  onSearch(paginationSize: number = this.paginationSize): void {
    this.paginationSize = paginationSize;
    if (paginationSize && paginationSize < this.groups.length) {
      this.groups = this.groups.slice(0, paginationSize);
    }
    this.lastFilter = this.createFilter();
    this.first = 0;
    this.searchGroups(this.lastFilter);
  }

  private searchGroups(groupFilter: GroupFilter): void {
    this.loading = true;
    this.groupService.getGroups(groupFilter)
      .pipe(delay(this.groupService.pageMinDelay))
      .subscribe((pagedGroups: PagedGroup) => {
        this.groups = pagedGroups.values;
        this.totalGroups = pagedGroups.totalElements;
        this.loading = false;
      });
  }

  private createFilter(): GroupFilter {
    return {
      page: 0,
      size: this.paginationSize,
      name: this.selectedName,
      sortList: this.sortList
    };
  }

  private processParams(params: Params): void {
    let searchAfterwards = false;
    const nameIsSet = StringUtils.isString(params.name);

    if (nameIsSet) {
      searchAfterwards = true;
      this.selectedName = params.name;
    }

    if (searchAfterwards) {
      this.onSearch();
    }
  }

}
