import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '../../../services/rest/authentication.service';
import {AuthenticationRequest} from '../../../dtos/authentication/AuthenticationRequest';
import {AppRoutingModule} from '../../../app-routing.module';
import {InputButtonIconType} from '../../core/button/button.utils';
import {DataService} from '../../../services/data.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  InputButtonIconType = InputButtonIconType;

  form: FormGroup;
  // After first submission attempt, form validation will start
  submitted = false;
  loading = false;

  redirectURL = AppRoutingModule.landingPageURL;

  constructor(private formBuilder: FormBuilder,
              private authenticationService: AuthenticationService,
              private dataService: DataService,
              private router: Router,
              private route: ActivatedRoute) {
    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(8)]]
    });
  }

  ngOnInit() {
    const params = this.route.snapshot.queryParams;
    if (params['redirectURL'] && params['redirectURL'].length > 0) {
      this.redirectURL = params['redirectURL'];
    }
  }

  /**
   * Form validation will start after the method is called, additionally an AuthRequest will be sent
   */
  onLogin() {
    this.submitted = true;
    if (this.form.valid) {
      const authRequest: AuthenticationRequest = new AuthenticationRequest(this.form.controls.email.value, this.form.controls.password.value);
      this.authenticateUser(authRequest);
    } else {
      console.log('Invalid input');
    }
  }

  /**
   * Send authentication data to the authService. If the authentication was successfully, the user will be forwarded to the message page
   *
   * @param authRequest authentication data from the user login form
   */
  authenticateUser(authRequest: AuthenticationRequest) {
    console.log('Try to authenticate user: ' + authRequest.email);
    this.loading = true;
    this.authenticationService.loginUser(authRequest)
      .then(() => {
        console.log('Successfully logged in user: ' + authRequest.email);
        this.dataService.init();
        this.loading = false;
        console.log('Redirect to ' + this.redirectURL);
        this.router.navigate([this.redirectURL]);
      })
      .catch(error => {
          console.log('Could not log in due to:');
          console.log(error);
          this.loading = false;
        }
      );
  }

  onRegister() {
    this.router.navigate(['registration']);
  }

}
