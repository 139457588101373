import {Component, EventEmitter, Input, Output} from '@angular/core';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {ButtonUtils, InputButtonIconType} from '../button.utils';

@Component({
  selector: 'core-button-icon',
  templateUrl: 'button-icon.component.html',
  styleUrls: ['button-icon.component.scss']
})
export class ButtonIconComponent {

  @Input() id: string;

  @Input() type: InputButtonIconType;
  @Input() tooltipPosition: string = 'top';

  @Output() click = new EventEmitter<any>();

  constructor() {
    if (!this.id) {
      this.id = 'core-button-icon-' + Math.random();
    }
  }

  getIconForType(type: InputButtonIconType): IconProp {
    return ButtonUtils.config.find(c => c.type === type).icon;
  }

  getClassForType(type: InputButtonIconType): string {
    return ButtonUtils.config.find(c => c.type === type).class + ' p-button-outlined p-button-rounded fa-icon-container';
  }

  getTooltipForType(type: InputButtonIconType): string {
    return ButtonUtils.config.find(c => c.type === type).tooltip;
  }

  getIconStyleForType(type: InputButtonIconType): string {
    return ButtonUtils.config.find(c => c.type === type).iconStyle;
  }

}
