import {Injectable} from '@angular/core';
import {MessageService as PrimeNgMessageService} from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  // this is the id used for the next message
  private nextId;

  constructor(private primeNgMessageService: PrimeNgMessageService) {
    // id starts with 1
    this.nextId = 1;
  }

  /**
   * Add a temporary success message with the given content.
   *
   * @param summary to display.
   * @param detail to display.
   * @return id of the created message
   */
  addSuccessMessage(detail: string, summary: string = 'Success!'): number {
    return this.addMessage('success', summary, detail);
  }

  /**
   * Add a temporary info message with the given content.
   *
   * @param summary to display.
   * @param detail to display.
   * @return id of the created message
   */
  addInfoMessage(detail: string, summary: string = 'Information'): number {
    return this.addMessage('info', summary, detail);
  }

  /**
   * Add a temporary warning message with the given content.
   *
   * @param summary to display.
   * @param detail to display.
   * @return id of the created message
   */
  addWarnMessage(detail: string, summary: string = 'Warning!'): number {
    return this.addMessage('warn', summary, detail);
  }

  /**
   * Add a sticky error message with the given content.
   *
   * @param summary to display.
   * @param detail to display.
   * @return id of the created message
   */
  addErrorMessage(detail: string, summary: string = 'Error!'): number {
    return this.addMessage('error', summary, detail, 10000);
  }

  /**
   * Clear all messages or the message with the given key.
   *
   * @param key to clear.
   */
  clear(key?: number): void {
    if (key) {
      this.primeNgMessageService.clear(key + '');
    } else {
      this.primeNgMessageService.clear();
    }
  }

  private addMessage(severity: string, summary: string, detail: string, life: number = 3000): number {
    this.primeNgMessageService.add({
      severity: severity,
      summary: summary,
      detail: detail,
      id: this.nextId,
      life: life
    });
    // return used id and increase for next message
    return this.nextId++;
  }

}
