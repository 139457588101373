<div class="core-edit-max-width">

  <h1>Benutzer Passwort ändern</h1>

  <form *ngIf="user && form" [formGroup]="form">

    <core-input-text
      formControlName="firstName"
      id="firstName"
      label="Vorname">
    </core-input-text>

    <core-input-text
      formControlName="lastName"
      id="lastName"
      label="Nachname">
    </core-input-text>

    <core-input-email
      formControlName="email"
      id="email"
      label="E-Mail">
    </core-input-email>

    <core-input-password
      formControlName="oldPassword"
      id="oldPassword"
      label="Altes Passwort">
    </core-input-password>

    <core-input-password
      formControlName="password"
      id="password"
      label="Neues Passwort">
    </core-input-password>

    <core-input-password
      formControlName="confirmPassword"
      id="confirmPassword"
      label="Passwort bestätigen">
    </core-input-password>

    <div class="flex-row-space-between">
      <core-button-slight
        (click)="onResetPassword(user)"
        *ngIf="form"
        [disabled]="form.disabled"
        [text]="'Password zurücksetzten'"
        [type]="InputButtonIconType.EDIT"
        id="resetPassword"
        style="margin-right: 10px">
      </core-button-slight>

      <core-button-submit
        (onClick)="submitChanges()"
        [disabled]="form.invalid || form.disabled"
        id="submit">
      </core-button-submit>
    </div>

  </form>

</div>
