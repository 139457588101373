import {PermissionTargetEnum} from './enums/PermissionTargetEnum';
import {IconProp} from '@fortawesome/fontawesome-svg-core';

export default {
  SIDE_MENU: [
    {
      name: 'MENU.ADMINISTRATION',
      items: [
        {
          name: 'MENU.ORGANIZATION',
          icon: ['fas', 'warehouse'] as IconProp,
          link: 'administration/organizations',
          permissions: [PermissionTargetEnum.ORGANIZATION]
        },
        {
          name: 'MENU.GROUPS',
          icon: ['fas', 'users'] as IconProp,
          link: 'administration/groups',
          permissions: [PermissionTargetEnum.GROUPS]
        },
        {
          name: 'MENU.PERMISSIONS',
          icon: ['fas', 'lock'] as IconProp,
          link: 'administration/permissions',
          permissions: [PermissionTargetEnum.PERMISSIONS]
        },
        {
          name: 'MENU.FILES',
          icon: ['fas', 'file'] as IconProp,
          link: 'administration/files',
          permissions: [PermissionTargetEnum.FILES]
        }
      ]
    }
  ],
  TOP_MENU: [
    {
      name: 'MENU.DASHBOARD',
      icon: ['fas', 'chart-column'] as IconProp,
      link: 'dashboard',
      permissions: [PermissionTargetEnum.NO_PERMISSION]
    },
    {
      name: 'MENU.PROJECTS',
      icon: ['fas', 'box-open'] as IconProp,
      link: 'projects',
      permissions: [PermissionTargetEnum.NO_PERMISSION]
    },
    {
      name: 'MENU.USER',
      icon: ['fas', 'user'] as IconProp,
      link: 'users',
      permissions: [PermissionTargetEnum.USERS]
    },
    {
      name: 'MENU.ADMINISTRATION',
      icon: ['fas', 'gear'] as IconProp,
      link: 'administration/organizations',
      permissions: [PermissionTargetEnum.NO_PERMISSION]
    }
  ]
};
