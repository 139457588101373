import {RouterModule, Routes} from '@angular/router';
import {AuthenticationTokenGuard} from './guards/authentication-token-guard.service';
import {NgModule} from '@angular/core';
import {UserTableComponent} from './components/users/table/user-table.component';
import {UserCreateComponent} from './components/users/create/user-create.component';
import {UserEditComponent} from './components/users/edit/user-edit.component';
import {LoginComponent} from './components/authentication/login/login.component';
import {RegistrationComponent} from './components/authentication/registration/registration.component';
import {PermissionTableComponent} from './components/permissions/table/permission-table.component';
import {PermissionEditComponent} from './components/permissions/edit/permission-edit.component';
import {GroupTableComponent} from './components/groups/table/group-table.component';
import {PermissionEditGuard} from './guards/permission-edit-guard.service';
import {PermissionTargetEnum} from './enums/PermissionTargetEnum';
import {GroupEditComponent} from './components/groups/edit/group-edit.component';
import {GroupCreateComponent} from './components/groups/create/group-create.component';
import {DashboardComponent} from './components/dashboard/dashboard.component';
import {UserChangePasswordComponent} from './components/users/change-password/user-change-password.component';
import {OrganizationEditComponent} from './components/organization/edit/organization-edit.component';
import {FileTableComponent} from './components/file/table/file-table.component';
import {FileCreateComponent} from './components/file/create/file-create.component';
import {FileEditComponent} from './components/file/edit/file-edit.component';
import {ProjectsComponent} from './components/projects/projects.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'registration',
    component: RegistrationComponent
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthenticationTokenGuard]
  },
  {
    path: 'projects',
    component: ProjectsComponent,
    canActivate: [AuthenticationTokenGuard]
  },
  {
    path: 'administration/permissions',
    component: PermissionTableComponent,
    canActivate: [AuthenticationTokenGuard]
  },
  {
    path: 'administration/permissions/:id',
    component: PermissionEditComponent,
    canActivate: [AuthenticationTokenGuard]
  },
  {
    path: 'administration/groups',
    component: GroupTableComponent,
    canActivate: [AuthenticationTokenGuard]
  },
  {
    path: 'administration/groups/create',
    component: GroupCreateComponent,
    canActivate: [AuthenticationTokenGuard, PermissionEditGuard],
    data: {permissionTarget: PermissionTargetEnum.GROUPS}
  },
  {
    path: 'administration/groups/:id',
    component: GroupEditComponent,
    canActivate: [AuthenticationTokenGuard]
  },
  {
    path: 'administration/organizations',
    component: OrganizationEditComponent,
    canActivate: [AuthenticationTokenGuard]
  },
  {
    path: 'users',
    component: UserTableComponent,
    canActivate: [AuthenticationTokenGuard]
  },
  {
    path: 'users/create',
    component: UserCreateComponent,
    canActivate: [AuthenticationTokenGuard, PermissionEditGuard],
    data: {permissionTarget: PermissionTargetEnum.USERS}
  },
  {
    path: 'users/change-password/:id',
    component: UserChangePasswordComponent,
    canActivate: [AuthenticationTokenGuard]
  },
  {
    path: 'users/:id',
    component: UserEditComponent,
    canActivate: [AuthenticationTokenGuard]
  },
  {
    path: 'administration/files',
    component: FileTableComponent,
    canActivate: [AuthenticationTokenGuard]
  },
  {
    path: 'administration/files/create',
    component: FileCreateComponent,
    canActivate: [AuthenticationTokenGuard]
  },
  {
    path: 'administration/files/:id',
    component: FileEditComponent,
    canActivate: [AuthenticationTokenGuard]
  },
  {path: '**', redirectTo: 'dashboard'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule {

  static readonly landingPageURL = 'dashboard';

}
