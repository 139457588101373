<button
  (click)="click.emit($event)"
  [classList]="getClassForType(type)"
  [id]="id"
  [pTooltip]="getTooltipForType(type)"
  [tooltipPosition]="tooltipPosition"
  pButton
  pRipple
  type="button">
  <fa-icon
    [icon]=getIconForType(type)
    [size]="'lg'"
    style="margin-left: 3px">
  </fa-icon>
</button>
