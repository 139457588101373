import {PermissionActionEnum} from '../../enums/PermissionActionEnum';
import {PermissionTargetEnum} from '../../enums/PermissionTargetEnum';

export class SimplePermission {

  action: PermissionActionEnum;
  target: PermissionTargetEnum;

  constructor(action: PermissionActionEnum,
              target: PermissionTargetEnum) {
    this.action = action;
    this.target = target;
  }

}
