export enum SortDirectionEnum {

  ASC = 'ASC',
  DESC = 'DESC'

}

export namespace SortDirectionEnum {

  export function getOpposite(direction: SortDirectionEnum): SortDirectionEnum {
    if (!direction) {
      return null;
    } else if (direction === SortDirectionEnum.ASC) {
      return SortDirectionEnum.DESC;
    } else {
      return SortDirectionEnum.ASC;
    }
  }

}
