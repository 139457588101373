import {APP_INITIALIZER, LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppComponent} from './app.component';
import {httpInterceptorProviders} from './interceptors/interceptor.index';
import {AppRoutingModule} from './app-routing.module';
import {SharedModule} from './shared/shared.module';
import {UserModule} from './components/users/user.module';
import {HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {LoginModule} from './components/authentication/login/login.module';
import {RegistrationModule} from './components/authentication/registration/registration.module';
import {CoreModule} from './components/core/core.module';
import {PermissionModule} from './components/permissions/permission.module';
import {GroupModule} from './components/groups/group.module';
import {DashboardModule} from './components/dashboard/dashboard.module';
import {DataService} from './services/data.service';
import {OrganizationModule} from './components/organization/organization.module';
import {FileModule} from './components/file/file.module';
import {PipesModule} from './pipes/pipes.module';
import {TranslationService} from './services/rest/translation.service';
import {ProjectsModule} from './components/projects/projects.module';
import {registerLocaleData} from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';

registerLocaleData(localeDe, 'de-DE', localeDeExtra);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    CoreModule,
    PipesModule,
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    HttpClientModule,
    AppRoutingModule,
    UserModule,
    FileModule,
    PermissionModule,
    ProjectsModule,
    GroupModule,
    LoginModule,
    RegistrationModule,
    DashboardModule,
    OrganizationModule
  ],
  exports: [],
  providers: [
    httpInterceptorProviders,
    {
      provide: APP_INITIALIZER,
      useFactory: (dataService: DataService) => () => {
        return dataService.init()
      },
      deps: [DataService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (translationService: TranslationService) => () => {
        return translationService.init()
      },
      deps: [TranslationService],
      multi: true
    },
    {
      provide: LOCALE_ID,
      useValue: 'de-DE'
    }
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {
}
