<button
  (click)="onClick.emit($event)"
  [classList]="getClassForType(type)"
  [disabled]="disabled"
  [id]="id"
  [ngClass]="{'core-button-border-less': !showBorder}"
  [ngStyle]="{width: width}"
  pButton
  pRipple
  type="button">
  <fa-icon
    *ngIf="icon"
    [icon]=icon
    [ngClass]="icon && text ? 'core-button-space-between-icon-and-text' : ''">
  </fa-icon>
  {{getTextForType(type)}}
</button>
