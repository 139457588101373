import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {MessageService} from '../services/message.service';
import {AuthenticationService} from '../services/rest/authentication.service';
import {AuthUtils} from '../utils/AuthUtils';
import {PermissionTargetEnum} from '../enums/PermissionTargetEnum';

@Injectable({
  providedIn: 'root'
})
export class PermissionEditGuard implements CanActivate {

  constructor(private authenticationService: AuthenticationService,
              private router: Router,
              private messageService: MessageService) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const permission = next.data['permissionTarget'] as PermissionTargetEnum;
    if (AuthUtils.canEdit(permission)) {
      return true;
    } else {
      this.messageService.addWarnMessage('Permission ' + permission + ' required to access this page!');
      // TODO
      console.log('Redirect to /login');
      this.router.navigate(['/login']);
      return false;
    }
  }

}
