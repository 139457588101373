import {Component, OnInit} from '@angular/core';
import {MessageService} from '../../../services/message.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {PermissionService} from '../../../services/rest/permission.service';
import {Permission} from '../../../dtos/permission/Permission';
import {PermissionUpdate} from '../../../dtos/permission/PermissionUpdate';
import {AuthUtils} from '../../../utils/AuthUtils';
import {PermissionTargetEnum} from '../../../enums/PermissionTargetEnum';
import {NumberUtils} from '../../../utils/NumberUtils';

@Component({
  selector: 'app-permission-edit',
  templateUrl: './permission-edit.component.html',
  styleUrls: ['./permission-edit.component.scss']
})
export class PermissionEditComponent implements OnInit {
  REDIRECT_ON_SUCCESS = '/permissions';
  REDIRECT_ON_ERROR = '/permissions';

  permission: Permission;

  form: FormGroup;

  userCanEdit: boolean = AuthUtils.canEdit(PermissionTargetEnum.PERMISSIONS);

  constructor(private formBuilder: FormBuilder,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private permissionService: PermissionService,
              private messageService: MessageService) {
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      const id = params.id;
      if (!NumberUtils.isNumber(id)) {
        this.messageService.addErrorMessage("Kein gültiger Parameter in der URL!");
        console.log('Given parameter is not valid: ' + id);
        console.log('Redirect to ' + this.REDIRECT_ON_ERROR);
        this.router.navigate([this.REDIRECT_ON_ERROR]);
        return;
      }
      this.getPermission(id);
    });
  }

  submitChanges() {
    if (this.form.valid) {
      const permission: PermissionUpdate = {
        id: this.form.controls.id.value,
        name: this.form.controls.name.value,
        description: this.form.controls.description.value,
      };
      this.permissionService.updatePermission(permission).subscribe(() => {
        this.messageService.addSuccessMessage('Berechtigung erfolgreich bearbeitet!');
      });
    }
  }

  private getPermission(id: number) {
    this.permissionService.getPermissionById(id).subscribe((permission: Permission) => {
      this.permission = permission;
      this.createForm(permission);
    }, () => {
      console.log('No permission found with id: ' + id);
      console.log('Redirect to ' + this.REDIRECT_ON_ERROR);
      this.router.navigate([this.REDIRECT_ON_ERROR]);
    });
  }

  private createForm(permission: Permission) {
    this.form = this.formBuilder.group({
      id: [permission.id],
      action: [permission.action, []],
      target: [permission.target, []],
      name: [permission.name, [Validators.required, Validators.minLength(5), Validators.maxLength(255)]],
      description: [permission.description, [Validators.maxLength(255)]]
    });
    this.form.controls.action.disable();
    this.form.controls.target.disable();
    if (!this.userCanEdit) {
      this.form.disable();
    }
  }

}
