import {Component, OnInit} from '@angular/core';
import {UserService} from '../../../services/rest/user.service';
import {User} from '../../../dtos/user/User';
import {MessageService} from '../../../services/message.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthenticationService} from '../../../services/rest/authentication.service';
import {UserDetail} from '../../../dtos/user/UserDetail';
import {PermissionService} from '../../../services/rest/permission.service';
import {GroupService} from '../../../services/rest/group.service';
import {AuthUtils} from '../../../utils/AuthUtils';
import {PermissionTargetEnum} from '../../../enums/PermissionTargetEnum';
import {InputButtonIconType} from '../../core/button/button.utils';
import {ConfirmService} from '../../../services/confirm.service';
import {AppRoutingModule} from '../../../app-routing.module';
import {UserChangePassword} from '../../../dtos/user/UserChangePassword';
import {InputPasswordComponent} from '../../core/input/input-password/input-password.component';
import {NumberUtils} from '../../../utils/NumberUtils';

@Component({
  selector: 'app-user-change-password',
  templateUrl: './user-change-password.component.html',
  styleUrls: ['./user-change-password.component.scss']
})
export class UserChangePasswordComponent implements OnInit {
  InputButtonIconType = InputButtonIconType;

  user: UserDetail;
  currentUser: User;
  form: FormGroup;
  idToEdit: number;

  constructor(private formBuilder: FormBuilder,
              private authService: AuthenticationService,
              private activatedRoute: ActivatedRoute,
              private userService: UserService,
              private permissionService: PermissionService,
              private groupService: GroupService,
              private messageService: MessageService,
              private confirmService: ConfirmService,
              private router: Router) {
  }

  ngOnInit() {
    // check if the current logged in user is allowed to change the password
    this.activatedRoute.params.subscribe((params: Params) => {
      this.idToEdit = +params.id;
    });
    this.getCurrentUser();
  }

  submitChanges() {
    if (this.form.valid) {
      const user: UserChangePassword = {
        oldPassword: this.form.controls.oldPassword.value,
        password: this.form.controls.password.value,
      };
      this.userService.changeUserPassword(user, this.idToEdit).subscribe(() => {
        this.messageService.addSuccessMessage('Passwort erfolgreich geändert!');
        this.createForm(this.user);
      });
    }
  }

  onResetPassword(user: User): void {
    this.confirmService.confirm(`Passwort zurücksetzen`,
      `Wollen Sie das Passwort für Benutzer ${user.firstName} ${user.lastName} wirklich zurücksetzen? <br>` +
      `Es wird eine E-Mail mit einem neuen Passwort an ${user.email} gesendet! <br>` +
      `Dieser Vorgang kann nicht rückgängig gemacht werden.`,
      () => {
        this.userService.resetUserPassword(user.email).toPromise().then(() => {
          this.messageService.addSuccessMessage(`Passwort von ${user.firstName} ${user.lastName} wurde erfolgreich zurückgesetzt!`);
        }).catch(err => {
          console.log(err);
        });
      });
  }

  private getCurrentUser() {
    this.userService.getCurrentUser().subscribe(currentUser => {
      this.currentUser = currentUser;
      if (!NumberUtils.isNumber(this.idToEdit)) {
        this.idToEdit = currentUser.id;
      }
      if (this.currentUser.id !== this.idToEdit && !AuthUtils.canEdit(PermissionTargetEnum.USERS_PASSWORD)) {
        this.messageService.addErrorMessage('Keine Berechtigung fürs Ändern von Passwörtern!');
        this.router.navigate([AppRoutingModule.landingPageURL]);
      } else if (this.currentUser.id === this.idToEdit) {
        this.user = currentUser;
        this.createForm(currentUser);
      } else {
        this.getUser(this.idToEdit);
      }
    });
  }

  private getUser(id: number) {
    this.userService.getUserById(id).subscribe((user: UserDetail) => {
      this.user = user;
      this.createForm(user);
    }, () => {
      console.log('No user found with id: ' + id);
      console.log('Redirect to ' + AppRoutingModule.landingPageURL);
      this.router.navigate([AppRoutingModule.landingPageURL]);
      return;
    });
  }

  private createForm(user: UserDetail) {
    this.form = this.formBuilder.group({
      id: [user.id],
      oldPassword: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(255)]],
      password: ['', [
        Validators.required,
        InputPasswordComponent.minOneLowerCaseCharacter(),
        InputPasswordComponent.minOneUpperCaseCharacter(),
        InputPasswordComponent.minOneNumberCaseCharacter(),
        Validators.minLength(8),
        Validators.maxLength(255)]],
      confirmPassword: ['', [InputPasswordComponent.matchValues('password'), Validators.required]],
      firstName: [user.firstName],
      lastName: [user.lastName],
      email: [user.email],
      menuExpanded: [user.menuExpanded],
      locked: [user.locked],
      permissions: [user.permissions.map(p => p.id)],
      groups: [user.groups.map(g => g.id)]
    });
    this.form.controls.firstName.disable();
    this.form.controls.lastName.disable();
    this.form.controls.email.disable();
    this.form.controls.menuExpanded.disable();
    this.form.controls.locked.disable();
    this.form.controls.permissions.disable();
    this.form.controls.groups.disable();
  }

}
