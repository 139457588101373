import {Component, EventEmitter, Input, Output} from '@angular/core';
import {IconProp} from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'core-button',
  templateUrl: 'button.component.html',
  styleUrls: ['button.component.scss']
})
export class ButtonComponent {

  @Input() id: string;

  @Input() disabled: boolean;
  @Input() text: string;
  @Input() icon: IconProp;
  @Input() width: string;

  @Output() onClick = new EventEmitter<any>();

  constructor() {
    if (!this.id) {
      this.id = 'core-button-' + Math.random();
    }
  }

}
