import {NgModule} from '@angular/core';
import {InputNumberModule} from 'primeng/inputnumber';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {MultiSelectModule} from 'primeng/multiselect';
import {TooltipModule} from 'primeng/tooltip';
import {ButtonComponent} from './button/button.component';
import {DropdownModule} from 'primeng/dropdown';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {ButtonIconComponent} from './button-icon/button-icon.component';
import {ButtonIconBigComponent} from './button-icon-big/button-icon-big.component';
import {ButtonModule} from 'primeng/button';
import {RippleModule} from 'primeng/ripple';
import {ButtonSubmitComponent} from './button-submit/button-submit.component';
import {ButtonSlightComponent} from './button-slight/button-slight.component';

@NgModule({
  declarations: [
    ButtonComponent,
    ButtonIconComponent,
    ButtonIconBigComponent,
    ButtonSubmitComponent,
    ButtonSlightComponent
  ],
  imports: [
    ButtonModule,
    InputNumberModule,
    DropdownModule,
    MultiSelectModule,
    ReactiveFormsModule,
    CommonModule,
    FormsModule,
    TooltipModule,
    FontAwesomeModule,
    RippleModule
  ],
  exports: [
    ButtonComponent,
    ButtonIconComponent,
    ButtonIconBigComponent,
    ButtonSubmitComponent,
    ButtonSlightComponent
  ]
})
export class CoreButtonModule {

}
