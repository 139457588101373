import {NgModule} from '@angular/core';
import {ChangeLanguageDialog} from './change-language/change-language.dialog';
import {DialogService} from 'primeng/dynamicdialog';
import {CoreInputModule} from '../input/core-input.module';
import {PipesModule} from '../../../pipes/pipes.module';
import {FormsModule} from '@angular/forms';
import {CoreButtonModule} from '../button/core-button.module';

@NgModule({
  declarations: [ChangeLanguageDialog],
  imports: [
    CoreInputModule,
    PipesModule,
    FormsModule,
    CoreButtonModule
  ],
  exports: [ChangeLanguageDialog],
  providers: [DialogService]
})
export class CoreDialogModule {
}
