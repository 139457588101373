<div class="core-edit-max-width">

  <h1>Datei erstellen</h1>

  <form *ngIf="form" [formGroup]="form">

    <core-input-file-upload
      formControlName="file"
      label="Datei">
    </core-input-file-upload>

    <core-input-text
      formControlName="name"
      id="name"
      label="Name">
    </core-input-text>

    <core-input-text
      formControlName="description"
      id="description"
      label="Beschreibung">
    </core-input-text>

    <core-button-submit
      [disabled]="form.invalid || form.disabled || true"
      id="button_submit">
    </core-button-submit>

  </form>

</div>
