<div class="core-edit-max-width">

  <h1>Gruppe erstellen</h1>

  <form *ngIf="form" [formGroup]="form">

    <core-input-text
      formControlName="name"
      id="name"
      label="Name">
    </core-input-text>

    <core-input-text
      formControlName="description"
      id="description"
      label="Beschreibung">
    </core-input-text>

    <core-input-multiselect
      [options]="allPermissions"
      formControlName="permissions"
      id="permissions"
      label="Berechtigungen">
    </core-input-multiselect>

    <core-button-submit
      (onClick)="submitChanges()"
      [disabled]="form.invalid || form.disabled"
      id="button_submit">
    </core-button-submit>

  </form>

</div>
