import {Injectable} from '@angular/core';
import {User} from '../dtos/user/User';
import {AppInfo} from '../dtos/general/AppInfo';
import {Organization} from '../dtos/organization/Organization';
import {AppInfoService} from './rest/app-info.service';
import {AuthenticationService} from './rest/authentication.service';
import {UserService} from './rest/user.service';
import {OrganizationService} from './rest/organization.service';
import {MessageService} from './message.service';
import {forkJoin} from "rxjs";
import {Router} from '@angular/router';
import {LanguageEnum} from '../enums/LanguageEnum';

/**
 * this service holds shared data which is valid all time the application is used
 */
@Injectable({
  providedIn: 'root'
})
export class DataService {

  // used language in the application
  private lang: string;

  // holds information about the application
  private appInfo: AppInfo;

  // holds the current logged-in user
  private loggedInUser: User;

  // holds the current selected organization
  // some users are allowed to change this and access other allOrganizations
  private organization: Organization;

  constructor(private appInfoService: AppInfoService,
              private authenticationService: AuthenticationService,
              private userService: UserService,
              private organizationService: OrganizationService,
              private messageService: MessageService,
              private router: Router) {
    // set preferred language to null which means the language of the browser will be used
    this.setLanguage(null);
  }

  init(): Promise<any> {
    const appInfo = this.fetchAppInfo();
    const user = this.onUserLogIn();
    const organization = this.fetchOrganization();
    return forkJoin([appInfo, user, organization]).toPromise();
  }

  getCurrentLanguage(): string {
    return this.lang;
  }

  /**
   * sets the language for the current user
   * if the user has a language preference set in the db used it
   * otherwise try to get the language from the browser
   *
   * @param lang which is preferred by the user
   */
  setLanguage(lang: string) {
    // check if there is a valid language provided which is supported
    if (lang && LanguageEnum.getAll().find(l => l === lang)) {
      this.lang = lang;
      return;
    }
    // if not get the set language from the browser and see if it is supported
    if (navigator.language?.slice(0, 2) && LanguageEnum.getAll().find(l => l === navigator.language?.slice(0, 2))) {
      this.lang = navigator.language?.slice(0, 2);
      return;
    }
    // otherwise fall back to the default value
    this.lang = LanguageEnum.getDefaultLang();
  }

  getAppInfo(): AppInfo {
    return this.appInfo;
  }

  getLoggedInUser(): User | null {
    return this.loggedInUser;
  }

  getOrganization(): Organization | null {
    return this.organization;
  }

  async onUserLogIn(): Promise<any> {
    const isLoggedIn: boolean = this.authenticationService.isLoggedIn();
    if (isLoggedIn) {
      return await this.fetchLoggedInUser().then(user => {
        // also start a timer which logs out the user when the jwt token gets invalid
        setTimeout(() => {
          // when this is reached logout the user
          if (this.authenticationService.getToken()) {
            this.messageService.addWarnMessage('Logout due to expired credentials!')
            this.authenticationService.logoutUser();
          }
        }, this.authenticationService.getTokenExpirationInMilliSeconds());
        // set the preferred language of the user, if null the languag from the browser will be taken
        this.setLanguage(user.language);
        return user;
      });
    } else {
      // redirect to login page if there is no user logged in
      this.authenticationService.removeTokenFromLocalStorageAndRedirectToLoginPage();
      this.onUserLogOut()
    }
  }

  onUserLogOut() {
    this.loggedInUser = null;
    this.organization = null;
  }

  fetchOrganization(): Promise<Organization> {
    return this.organizationService.getCurrentOrganization().toPromise().then(o => this.organization = o);
  }

  private fetchAppInfo(): Promise<AppInfo> {
    return this.appInfoService.getAppInfo().toPromise().then((appInfo: AppInfo) => {
      this.appInfo = appInfo;
      return appInfo;
    });
  }

  private fetchLoggedInUser(): Promise<User> {
    return this.userService.getCurrentUser().toPromise()
      .then(currentUser => {
        this.loggedInUser = currentUser;
        return currentUser;
      });
  }

}
