import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ButtonUtils, InputButtonIconType} from '../button.utils';
import {IconProp} from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'core-button-slight',
  templateUrl: 'button-slight.component.html',
  styleUrls: ['button-slight.component.scss']
})
export class ButtonSlightComponent {

  @Input() id: string;

  @Input() disabled: boolean;
  @Input() type: InputButtonIconType;
  @Input() width: string;
  @Input() icon: IconProp;
  @Input() showBorder: boolean = true;

  @Input() text: string;

  @Output() onClick = new EventEmitter<any>();

  constructor() {
    if (!this.id) {
      this.id = 'core-button-' + Math.random();
    }
  }

  getClassForType(type: InputButtonIconType): string {
    return ButtonUtils.config.find(c => c.type === type)?.class + ' p-button-outlined';
  }

  getTextForType(type: InputButtonIconType): string {
    return this.text ? this.text : ButtonUtils.config.find(c => c.type === type).text;
  }

}
