<div class="h-100" style="display: flex; flex-direction: column">

  <div class="flex-row-space-between">

    <h1>Benutzer</h1>

    <core-button-icon-big
      (click)="onCreateUser()"
      *ngIf="userCanEdit"
      [type]="InputButtonIconType.ADD"
      id="button-create-user">
    </core-button-icon-big>

  </div>

  <div class="row">

    <core-input-email
      (onKeyUpEnter)="onSearch()"
      [(ngModel)]="selectedEmail"
      [removeDefaultBottomErrorMargin]="true"
      class="col-10"
      id="email"
      placeholder="E-Mail">
    </core-input-email>

    <core-button
      (click)="onSearch()"
      [icon]="['fas', 'search']"
      [width]="'100%'"
      class="col-2"
      id="search"
      text="Suchen">
    </core-button>

  </div>

  <p-divider [style]="{marginBottom: '0.8em',marginTop: '0.8em'}" align="left">
    <core-button-slight
      (onClick)="hideExpandedSearch=!hideExpandedSearch; calculateNumberOfRowsForTableBasedOnParentDivSizeAndFetchUsers()"
      [type]="InputButtonIconType.PRIMARY"
      id="detailedSearch"
      text="Erweitert">
    </core-button-slight>
  </p-divider>

  <div [hidden]="hideExpandedSearch">
    <div class="row">

      <core-input-text
        (onKeyUpEnter)="onSearch()"
        [(ngModel)]="selectedFirstName"
        class="col-4"
        id="firstName"
        placeholder="Vorname">
      </core-input-text>

      <core-input-text
        (onKeyUpEnter)="onSearch()"
        [(ngModel)]="selectedLastName"
        class="col-4"
        id="lastName"
        placeholder="Nachname">
      </core-input-text>

      <core-input-dropdown
        (ngModelChange)="onSearch()"
        [(ngModel)]="selectedLockedValue"
        [binary]="true"
        class="col-4"
        id="locked"
        placeholder="Gesperrt">
      </core-input-dropdown>

    </div>

  </div>

  <!-- Results -->
  <div #tableParentDiv class="h-100">
    <p-table
      (onLazyLoad)="searchLazy($event)"
      *ngIf="paginationSize > 0"
      [(first)]="first"
      [autoLayout]="true"
      [lazy]="true"
      [loading]="loading"
      [paginator]="totalUsers > paginationSize"
      [rows]="paginationSize"
      [showCurrentPageReport]="true"
      [totalRecords]="totalUsers"
      [value]="users"
      currentPageReportTemplate="Zeige {first} bis {last} von {totalRecords} Benutzern"
      dataKey="id">
      <ng-template pTemplate="header">
        <tr>
          <th
            (click)="onSortOrderChanged('firstName')">
            <span>
              <span>Vorname</span>
              <span *ngIf="getSortIndexByFieldName('firstName')">
                {{getSortIndexByFieldName('firstName')}}
                <fa-icon [icon]="getSortIconByFieldName('firstName')"></fa-icon>
              </span>
            </span>
          </th>
          <th
            (click)="onSortOrderChanged('lastName')">
            <span>
              <span>Nachname</span>
              <span *ngIf="getSortIndexByFieldName('lastName')">
                {{getSortIndexByFieldName('lastName')}}
                <fa-icon [icon]="getSortIconByFieldName('lastName')"></fa-icon>
              </span>
            </span>
          </th>
          <th
            (click)="onSortOrderChanged('email')">
            <span>
              <span>E-Mail</span>
              <span *ngIf="getSortIndexByFieldName('email')">
                {{getSortIndexByFieldName('email')}}
                <fa-icon [icon]="getSortIconByFieldName('email')"></fa-icon>
              </span>
            </span>
          </th>
          <th
            (click)="onSortOrderChanged('locked')">
            <span>
              <span>Gesperrt</span>
              <span *ngIf="getSortIndexByFieldName('locked')">
                {{getSortIndexByFieldName('locked')}}
                <fa-icon [icon]="getSortIconByFieldName('locked')"></fa-icon>
              </span>
            </span>
          </th>
          <th
            [ngClass]="userCanEdit ? 'user-table-column-action-buttons-width' : 'user-table-column-action-buttons-width-without-edit-access'"></th>
        </tr>
      </ng-template>
      <ng-template let-user pTemplate="body">
        <tr *ngIf="!loading">
          <td style="width: 20%">{{user.firstName}}</td>
          <td style="width: 20%">{{user.lastName}}</td>
          <td style="width: 45%">{{user.email}}</td>
          <td style="width: 15%">{{booleanGerman(user.locked)}}</td>
          <td
            [ngClass]="userCanEdit ? 'user-table-column-action-buttons-width' : 'user-table-column-action-buttons-width-without-edit-access'"
            class="user-table-column-action-buttons">
            <core-button-icon
              (click)="onEditUserClick(user)"
              [type]="(userCanEdit && user.email !== ADMIN_EMAIL) ? InputButtonIconType.EDIT : InputButtonIconType.READ">
            </core-button-icon>
            <core-button-icon
              (click)="onLockUserClick(user)"
              *ngIf="!user.locked && userCanEdit && currentUser.id !== user.id"
              [type]="InputButtonIconType.LOCK">
            </core-button-icon>
            <core-button-icon
              (click)="onLockUserClick(user)"
              *ngIf="user.locked && userCanEdit && currentUser.id !== user.id"
              [type]="InputButtonIconType.UNLOCK">
            </core-button-icon>
            <core-button-icon
              (click)="onDeleteUserClick(user)"
              *ngIf="userCanEdit && currentUser.id !== user.id"
              [type]="InputButtonIconType.DELETE">
            </core-button-icon>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="loadingbody">
        <ng-container *ngFor="let p of ArrayUtils.of(paginationSize)">
          <tr style="height: 40px">
            <td style="width: 16%">
              <p-skeleton></p-skeleton>
            </td>
            <td style="width: 16%">
              <p-skeleton></p-skeleton>
            </td>
            <td style="width: 34%">
              <p-skeleton></p-skeleton>
            </td>
            <td style="width: 12%">
              <p-skeleton></p-skeleton>
            </td>
            <td style="width: 12%">
              <p-skeleton></p-skeleton>
            </td>
          </tr>
        </ng-container>
      </ng-template>
    </p-table>
  </div>
</div>
