import {IconProp} from '@fortawesome/fontawesome-svg-core';

export enum InputButtonIconType {
  PRIMARY = 'PRIMARY',
  ADD = 'ADD',
  READ = 'READ',
  EDIT = 'EDIT',
  LOCK = 'LOCK',
  UNLOCK = 'UNLOCK',
  DELETE = 'DELETE'
}

export class ButtonUtils {

  static config: { type: InputButtonIconType, text: string, icon: IconProp, class: string, iconStyle: string, tooltip: string }[] = [
    {type: InputButtonIconType.PRIMARY, text: null, icon: null, class: '', iconStyle: 'margin-left: 3px', tooltip: ''},
    {
      type: InputButtonIconType.ADD,
      text: 'Hinzufügen',
      icon: ['fas', 'plus'],
      class: 'p-button-success',
      iconStyle: 'margin-left: 3px',
      tooltip: 'Hinzufügen'
    },
    {
      type: InputButtonIconType.READ,
      text: 'Lesen',
      icon: ['fas', 'eye'],
      class: 'p-button-warning',
      iconStyle: 'margin-left: 1px',
      tooltip: 'Detailansicht'
    },
    {
      type: InputButtonIconType.EDIT,
      text: 'Bearbeiten',
      icon: ['fas', 'pen'],
      class: 'p-button-warning',
      iconStyle: 'margin-left: 3px',
      tooltip: 'Bearbeiten'
    },
    {
      type: InputButtonIconType.LOCK,
      text: 'Sperren',
      icon: ['fas', 'lock'],
      class: 'p-button-primary',
      iconStyle: 'margin-left: 3px',
      tooltip: 'Sperren'
    },
    {
      type: InputButtonIconType.UNLOCK,
      text: 'Entsperren',
      icon: ['fas', 'unlock'],
      class: 'p-button-primary',
      iconStyle: 'margin-left: 3px',
      tooltip: 'Entsperren'
    },
    {
      type: InputButtonIconType.DELETE,
      text: 'Löschen',
      icon: ['fas', 'trash'],
      class: 'p-button-danger',
      iconStyle: 'margin-left: 3px',
      tooltip: 'Löschen'
    },
  ];

}
