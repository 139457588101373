import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Globals} from '../../global/globals';
import {Observable} from 'rxjs';
import {File} from '../../dtos/file/File';
import {Sort} from '../../components/core/table/sort/Sort';
import {PagedFile} from '../../dtos/file/PagedFile';
import {FileFilter} from '../../dtos/file/FileFilter';
import {FileCreate} from '../../dtos/file/FileCreate';
import {FileUpdate} from '../../dtos/file/FileUpdate';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  pageMinDelay: number = this.globals.pageMinDelay;

  private fileBaseUrl: string = this.globals.backendUri + '/files';

  constructor(private http: HttpClient,
              private globals: Globals) {
  }

  /**
   * Loads all files from the backend
   */
  getFiles(fileFilter: FileFilter): Observable<PagedFile> {
    console.log('getFiles', JSON.stringify(fileFilter));
    if (!fileFilter) {
      console.log('FileFilter must be defined');
      return new Observable<PagedFile>();
    }
    if (fileFilter.page === null || fileFilter.page === undefined || fileFilter.page < 0) {
      console.log('Invalid page value ' + fileFilter.page);
      return new Observable<PagedFile>();
    }
    if (!fileFilter.size || fileFilter.size < 1) {
      console.log('Invalid page size ' + fileFilter.size);
      return new Observable<PagedFile>();
    }

    let params = new HttpParams();
    params = params.set('size', fileFilter.size.toString());
    params = params.set('page', fileFilter.page.toString());
    params = params.set('sortList', Sort.mapList(fileFilter.sortList));
    if (fileFilter.name) {
      params = params.set('name', fileFilter.name);
    }
    return this.http.get<PagedFile>(this.fileBaseUrl, {params});
  }

  getFile(id: number): Observable<File> {
    return this.http.get<File>(this.fileBaseUrl + '/' + id, {responseType: 'json'});
  }

  /**
   * create a new file
   *
   * @param file
   */
  createFile(file: FileCreate): Observable<File> {
    console.log('Create file with name ' + file.file.name);
    const formData: FormData = new FormData();
    formData.append('file', file.content);
    formData.append('fileCreateDto', JSON.stringify(file.file));
    return this.http.post<File>(this.fileBaseUrl, formData, {responseType: 'json'});
  }

  updateFile(file: FileUpdate): Observable<File> {
    console.log('Update file with id ' + file?.file?.id);
    const formData: FormData = new FormData();
    formData.append('file', file.content);
    formData.append('fileUpdateDto', JSON.stringify(file.file));
    return this.http.put<File>(this.fileBaseUrl, formData, {responseType: 'json'});
  }

  /**
   * delete a file
   *
   * @param id
   */
  deleteFileById(id: number) {
    console.log('Delete file with id ' + id);
    return this.http.delete(this.fileBaseUrl + '/' + id);
  }

}
