<div
  *ngIf="value?.length > 0"
  [ngClass]="label ? 'core_input_error_container_with_label' : 'core_input_error_container_with_float_label'"
  class="core-input-multiselect-chip-area">
  <p-chip
    (onRemove)="onChipRemove(option)"
    *ngFor="let option of value"
    [label]="getLabelForValue(option)"
    [removable]="!disabled"
    [style]="{opacity: disabled ? 0.5 : ''}"
    styleClass="p-mr-2 core-input-multiselect-chip">
  </p-chip>
</div>
<label *ngIf="topLabel" [for]="id" class="core_input_top_label">{{topLabel}}</label>
<div class="core-input-label-container">
  <label *ngIf="label">{{label}}</label>
  <div [ngClass]="floatLabel ? 'core-input-container-float-label p-float-label' : ''"
       class="p-inputgroup core-input-container core_input_flex_between">
    <p-multiSelect
      (keyup.enter)="onKeyUpEnter.emit(value)"
      (ngModelChange)="valueChanged($event)"
      (onPanelShow)="attachCustomHandlers()"
      [disabled]="disabled"
      [id]="id"
      [ngClass]="control.touched && control.errors ? 'core_input_invalid' : ''"
      [ngModel]="value"
      [options]="_options"
      [placeholder]="placeholder"
      [style]="{width: '100%',
      'border-color': control.touched && control.errors ? 'inherit' : '',
      'border-top-right-radius': 0,
      'border-bottom-right-radius': 0,
      height: '35px'}"
      optionLabel="name"
      optionValue="id">
      <ng-template pTemplate="selectedItems">
        <div style="display: inline-flex;">
          <ng-container *ngIf="showChipsInField">
            <ng-container *ngFor="let option of value.slice(0, maxSelectedLabels)">
              <p-chip
                (onRemove)="onChipRemove(option)"
                [label]="getLabelForValue(option)"
                [removable]="true"
                styleClass="p-mr-2 core-input-multiselect-chip">
              </p-chip>
            </ng-container>
            <div *ngIf="value && value.length > 5" class="core-input-multiselect-chip-text">
              und {{value.length - maxSelectedLabels}} mehr ...
            </div>
          </ng-container>
          <div *ngIf="!showChipsInField" class="core-input-multiselect-chip-text">
            <ng-container *ngIf="value?.length > 1">
              {{value.length}} Elemente ausgewählt
            </ng-container>
            <ng-container *ngIf="value?.length == 1">
              {{value.length}} Element ausgewählt
            </ng-container>
          </div>
        </div>
      </ng-template>
    </p-multiSelect>
    <label *ngIf="floatLabel" [for]="id" class="core_input_float_label">{{floatLabel}}</label>
    <button
      (click)="onClear()"
      [disabled]="disabled"
      class="p-button-secondary core-input-button-clear p-inputgroup-addon"
      icon="pi pi-times"
      pButton
      tabindex="-1">
    </button>
  </div>
</div>
<div
  [ngClass]="[label ? 'core_input_error_container_with_label' : 'core_input_error_container_with_float_label', removeDefaultBottomErrorMargin ? '' : 'core_input_error_container_min_height']"
  class="core_input_error">
  <small *ngIf="control.touched && control.errors?.required">
    Dieses Feld ist erforderlich!
  </small>
</div>
