import {ElementRef} from '@angular/core';

export class TablePaginationUtils {

  static calculateNumberOfRowsForTableBasedOnParentDivSizeAndFetchData(
    div: ElementRef,
    onSearch: (paginationSize: number) => void,
    defaultHeaderSize: number = 40.5,
    defaultFooterSize: number = 40,
    defaultRowHeight: number = 40.5) {
    setTimeout(() => {
      const tableHeight = div.nativeElement.clientHeight;
      console.log('Table height: ' + tableHeight + 'px');
      console.log('Row height: ' + defaultRowHeight + 'px');
      console.log('Header height: ' + defaultHeaderSize + 'px');
      console.log('Footer height: ' + defaultFooterSize + 'px');
      let paginationSize = Math.floor((tableHeight - defaultHeaderSize - defaultFooterSize) / defaultRowHeight);
      console.log('Number of rows: ' + paginationSize);
      if (paginationSize <= 1) paginationSize = 1;
      onSearch(paginationSize);
    }, 1);
  }

}
