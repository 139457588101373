import {Component, EventEmitter, Injector, Input, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl} from '@angular/forms';
import {SelectItem} from '../../../../dtos/SelectItem';

@Component({
  selector: 'core-input-multiselect',
  templateUrl: 'input-multiselect.component.html',
  styleUrls: ['input-multiselect.component.scss', '../core-input.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: InputMultiselectComponent,
    multi: true
  }]
})
export class InputMultiselectComponent implements ControlValueAccessor, OnInit {

  @Input() id: string;
  @Input() label: string;
  @Input() floatLabel: string;
  @Input() topLabel: string;
  @Input() placeholder: string = '';
  @Input() showIconClass: boolean = false;
  @Input() showClearButton: boolean = true;

  @Input() removeDefaultBottomErrorMargin: boolean = false;

  @Input() showChipsInField: boolean = false;
  @Input() maxSelectedLabels: number = 5;
  @Output() onKeyUpEnter = new EventEmitter<any[]>();
  hasItemFocus = false;
  control: NgControl;
  value: any[] = [];
  disabled = false;
  private onTouched: Function;
  private onChanged: Function;

  constructor(private inj: Injector) {
    if (!this.id) {
      this.id = 'core-input-multiselect-' + Math.random();
    }
  }

  _options: SelectItem[] = [];

  @Input() set options(options: SelectItem[]) {
    if (!options) {
      options = [];
    }
    this._options = options.map(o => {
      const option = o;
      if (!option.name) {
        return null;
      }
      option.name = option.name.length > 50 ? option.name.substr(0, 50) + '...' : option.name;
      return option;
    });
  }

  ngOnInit() {
    this.control = this.inj.get(NgControl)
  }

  onClear() {
    this.valueChanged([]);
    this.onKeyUpEnter.emit(null)
  }

  valueChanged(value: any) {
    // null is not allowed, set it to an empty list
    if (!value) {
      value = [];
    }
    this.onTouched();
    this.value = value;
    this.onChanged(value);
  }

  writeValue(value: any): void {
    // null is not allowed, set it to an empty list
    if (!value) {
      value = [];
    }
    this.value = value;
  }

  registerOnChange(fn: Function): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: Function): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  getLabelForValue(value: any): string {
    // max length of this label is 50 characters
    const label = this._options.find(o => o.id === value)?.name;
    if (!label) {
      return null;
    }
    return label.length > 50 ? label.substr(0, 50) + '...' : label;
  }

  onChipRemove(value: any) {
    const index = this.value.findIndex(o => o === value);
    if (index >= 0) {
      this.value.splice(index, 1);
    }
  }

  attachCustomHandlers() {
    //input is only present if [filter]="true"
    const input = document.querySelector('.p-multiselect-filter');
    if (input) {
      input.addEventListener('focus', (event) => {
        this.hasItemFocus = false;
      });
    } else {
      const first = this.findFirstMultiselectItem();
      if (first) {
        first.focus({preventScroll: true});
        this.hasItemFocus = true;
      }
    }

    const multiselectHeader = document.querySelector('.p-multiselect-header');
    multiselectHeader.addEventListener('keyup', (event: Event) => {
      if ((<KeyboardEvent>event).code === 'ArrowDown' && !this.hasItemFocus) {
        const first = this.findFirstMultiselectItem();
        if (first) {
          first.focus({preventScroll: true});
          this.hasItemFocus = true;
          event.preventDefault();
        }
      }
    });
    this.hasItemFocus = false;
  }

  findFirstMultiselectItem() {
    //path to <ul> which holds all p-multiselect-items -> here we must query first item in case it uses virtual scroll
    const itemList = document.querySelector('.p-multiselect-panel .p-multiselect-items');
    //try to find first p-multiselect-item and set focus to it
    return <HTMLScriptElement>itemList.querySelector('.p-multiselect-item');
  }

}
