import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {AuthenticationService} from '../services/rest/authentication.service';
import {Observable} from 'rxjs';
import {Globals} from '../global/globals';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {

  constructor(private authenticationService: AuthenticationService, private globals: Globals) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authenticationUri = this.globals.backendUri + '/authentication';
    const registrationUri = this.globals.backendUri + '/users/register';

    // Do not intercept authentication requests
    // Do not intercept registration requests
    if (req.url === authenticationUri || req.url == registrationUri) {
      return next.handle(req);
    }

    const options: any = {};

    // add token if present
    const token = this.authenticationService.getToken();
    if (token && token.length > 0) {
      options.headers = req.headers.set('Authorization', token);
    }

    return next.handle(req.clone(options));
  }

}
