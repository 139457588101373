<div class="row pt-5">

  <div class="col-md-6 mx-auto">

    <span class="anchor" id="formLogin"></span>

    <div class="card login-card-body-border-radius">

      <div class="card-header flex-row-space-between">
        <h3 class="mb-0">{{'AUTHENTICATION.LOGIN.LOGIN' | translate}}</h3>
        <core-button-slight
          (click)="onRegister()"
          [text]="'AUTHENTICATION.LOGIN.REGISTER' | translate"
          [type]="InputButtonIconType.PRIMARY"
          id="register">
        </core-button-slight>
      </div>

      <form [formGroup]="form" class="login-card-body">

        <core-input-email
          [showClearButton]="false"
          [topLabel]="'AUTHENTICATION.LOGIN.EMAIL' | translate"
          formControlName="email"
          id="email">
        </core-input-email>

        <core-input-password
          [enableBrowserSuggestion]="true"
          [showClearButton]="false"
          [topLabel]="'AUTHENTICATION.LOGIN.PASSWORD' | translate"
          formControlName="password"
          id="password">
        </core-input-password>

        <core-button
          (click)="onLogin()"
          [icon]="['fas', 'check']"
          [text]="'AUTHENTICATION.LOGIN.LOGIN' | translate"
          [width]="'50%'"
          id="submit"
          style="text-align: center;">
        </core-button>

      </form>

      <p-progressBar *ngIf="loading" [style]="{'height': '10px'}" mode="indeterminate"></p-progressBar>

    </div>

  </div>

</div>
