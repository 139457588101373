<div class="side-menu-container">

  <!-- Navigation -->
  <div class="side-menu-body">

    <ng-container *ngFor="let list of Constants.SIDE_MENU">
      <ng-container *ngIf="isGroupMenuVisible(list)">
        <div *ngIf="menuExpanded && false" class="side-menu-body-menu-header">
          {{list.name | translate}}
        </div>
        <ng-container *ngFor="let items of list.items">
          <div
            *ngIf="isMenuVisible(items.permissions)"
            [routerLink]="items.link"
            class="side-menu-body-menu-item side-menu-flex"
            routerLinkActive="side-menu-item-active">
            <fa-icon [fixedWidth]="true" [icon]="items.icon" class="side-menu-body-menu-item-icon"></fa-icon>
            <div *ngIf="menuExpanded" class="side-menu-body-menu-item-text">{{items.name | translate}}</div>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>

  </div>

</div>
