<button
  (click)="click.emit($event)"
  [classList]="getClassForType(type)"
  [id]="id"
  [pTooltip]="getTooltipForType(type)"
  [tooltipPosition]="tooltipPosition"
  pButton
  pRipple
  type="button">
  <fa-icon
    [icon]=getIconForType(type)
    [style]="getIconStyleForType(type)"
    class="fa-icon-font-size">
  </fa-icon>
</button>
