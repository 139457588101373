import {Injectable, Type} from '@angular/core';
import {DialogService as PDialogService, DynamicDialogRef} from 'primeng/dynamicdialog';

@Injectable({
  providedIn: 'root'
})
export class CoreDialogService {

  ref: DynamicDialogRef | undefined;
  onClose: (val: any) => void;

  // quick reminder: dialogs are bad and should not be used inside modern applications!
  constructor(public dialogService: PDialogService) {
  }

  /**
   * create a new confirm dialog which the user has to either accept or reject
   *
   * @param componentType type of the component rendered inside the dialog
   * @param header name of the dialog
   * @param onClose (optional) callback function which is triggered when the user closes the dialog
   * @param width (optional) default value is 70%
   * @param contentStyle (optional) default value is {"overflow": "auto"}
   * @param maximizable (optional) default value is false
   */
  create(componentType: Type<any>,
         header: string,
         onClose: (val: any) => void = (val) => {
         },
         width: string = '32%',
         contentStyle: any = {"overflow": "auto"},
         maximizable: boolean = false) {
    this.ref = this.dialogService.open(componentType,
      {
        header: header,
        width: width,
        contentStyle: contentStyle,
        baseZIndex: 100000,
        maximizable: maximizable
      });

    this.onClose = onClose;

    this.ref.onClose.subscribe((val: any) => {
      this.close(val);
    });

  }

  close(val?: any) {
    if (this.onClose != null) {
      this.onClose(val);
    }
    // destroy all ui elements in the dialog
    this.ref.destroy();
  }

}
