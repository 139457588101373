import {Component} from '@angular/core';
import {SelectItem} from '../../../../dtos/SelectItem';
import {DataService} from '../../../../services/data.service';
import {InputButtonIconType} from '../../button/button.utils';
import {CoreDialogService} from '../core-dialog.service';
import {UserService} from '../../../../services/rest/user.service';
import {TranslationService} from '../../../../services/rest/translation.service';

@Component({
  selector: 'core-change-language-dialog',
  templateUrl: 'change-language.dialog.html',
  styleUrls: ['change-language.dialog.scss']
})
export class ChangeLanguageDialog {

  InputButtonIconType = InputButtonIconType;

  selection: number;
  options: SelectItem[];
  languages: { lang: string, name: string, iconClass: string }[];

  constructor(private dataService: DataService,
              private coreDialogService: CoreDialogService,
              private userService: UserService,
              private translationService: TranslationService) {
    // this has to be mapped to numbers to support the select-item object
    this.languages = translationService.getTranslations();
    this.options = this.languages.map((l, index) => ({
      id: index + 1,
      name: l.name,
      description: l.lang,
      iconClass: l.iconClass
    }));
    this.selection = this.options.find(l => l.description === this.dataService.getCurrentLanguage()).id;
  }

  async onSubmit() {
    // save lang to user
    await this.userService.updateLanguageOfLoggedInUser(this.languages[this.selection - 1].lang)
      .subscribe(resp => {
        console.log('Language saved!');
        // and reload page
        window.location.reload();
      });
  }

  closeDialog() {
    this.coreDialogService.close();
  }

}

