<label *ngIf="topLabel" [for]="id" class="core_input_top_label">{{topLabel}}</label>
<div class="core-input-label-container">
  <label *ngIf="label">{{label}}</label>
  <div [ngClass]="floatLabel ? 'core-input-container-float-label p-float-label' : ''"
       class="p-inputgroup core-input-container core_input_flex_between">
    <p-inputNumber
      (keyup.enter)="onKeyUpEnter.emit(value)"
      (ngModelChange)="valueChanged($event)"
      [disabled]="disabled"
      [id]="id"
      [maxFractionDigits]="maxFractionDigits"
      [max]="max"
      [minFractionDigits]="minFractionDigits"
      [min]="min"
      [ngClass]="control.touched && control.errors ? 'core_input_invalid' : ''"
      [ngModel]="value"
      [placeholder]="placeholder"
      [step]="step"
      [style]="{width: showClearButton ? 'calc(100% - 40px)' : '100%'}"
      class="core-input"
      currency="EUR"
      locale="de-DE"
      mode="currency">
    </p-inputNumber>
    <label *ngIf="floatLabel" [for]="id" class="core_input_float_label">{{floatLabel}}</label>
    <button
      (click)="onClear()"
      *ngIf="showClearButton"
      [disabled]="disabled"
      class="p-button-secondary core-input-button-clear p-inputgroup-addon"
      icon="pi pi-times"
      pButton
      tabindex="-1">
    </button>
  </div>
</div>
<div
  [ngClass]="[label ? 'core_input_error_container_with_label' : 'core_input_error_container_with_float_label', removeDefaultBottomErrorMargin ? '' : 'core_input_error_container_min_height']"
  class="core_input_error">
  <small *ngIf="control.touched && control.errors?.required">
    Dieses Feld ist erforderlich!
  </small>
  <small *ngIf="control.touched && control.errors?.minlength">
    Dieses Feld muss mindestens {{control.errors.minlength.requiredLength}} Zeichen lang sein!
  </small>
  <small *ngIf="control.touched && control.errors?.maxlength">
    Dieses Feld darf maximal {{control.errors.maxlength.requiredLength}} Zeichen lang sein!
  </small>
</div>
