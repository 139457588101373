import {Component, OnInit} from '@angular/core';
import {ProjectService} from '../../services/rest/project.service';
import {ProjectFilter} from '../../dtos/project/ProjectFilter';
import {Project} from '../../dtos/project/Project';
import {InputButtonIconType} from '../core/button/button.utils';
import {AuthUtils} from '../../utils/AuthUtils';
import {PermissionTargetEnum} from '../../enums/PermissionTargetEnum';

@Component({
  selector: 'app-projects',
  templateUrl: 'projects.component.html',
  styleUrls: ['projects.component.scss']
})
export class ProjectsComponent implements OnInit {

  InputButtonIconType = InputButtonIconType;

  userCanEdit: boolean = true;

  searchString: string;

  locations = ['Wien', 'Linz', 'Graz'];

  projects: Project[] = [];

  constructor(private projectService: ProjectService) {
  }

  ngOnInit(): void {
    this.getAllProjects();
  }

  getAllProjects() {
    this.projectService.getProjects(new ProjectFilter(20, 1, []))
      .subscribe(val => this.projects = val.values);
  }

  onCreateProject() {

  }

  onSearch() {

  }


}
