import {Component, Input} from '@angular/core';
import {Project} from '../../../dtos/project/Project';

@Component({
  selector: 'app-project-card',
  templateUrl: 'project-card.component.html',
  styleUrls: ['project-card.component.scss']
})
export class ProjectCardComponent {

  @Input() project: Project;

  today = new Date(new Date().setDate(new Date().getDate() - 1));

  calculateBudgetPercentage(project: Project): number {
    if (project.assignedCosts == 0) {
      return project.bookedCosts > 0 ? 100 : 0;
    }
    return 100 / project.assignedCosts * project.bookedCosts;
  }

  calculateDays(): number {
    const currentDate = new Date(); // today's date
    const endDate = new Date(this.project.endDate); // ensure it's a Date object

    // Set both dates to midnight for an accurate day difference calculation
    const today = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
    const end = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());

    const millisecondsPerDay = 1000 * 60 * 60 * 24;
    const diffTime = end.getTime() - today.getTime(); // difference in milliseconds
    const diffDays = Math.ceil(diffTime / millisecondsPerDay); // convert to days

    return Math.abs(diffDays);
  }

  getFontColor(project: Project): string {
    const percentage = this.calculateBudgetPercentage(project);
    let color;
    if (percentage < 80)
      color = "#137502E4";
    else if (percentage <= 100)
      color = "#E09300D1";
    else
      color = "#A91A00E2";
    return color;
  }

}
