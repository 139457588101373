import {Component, EventEmitter, Injector, Input, OnInit, Output} from '@angular/core';
import {AbstractControl, ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl, ValidationErrors} from '@angular/forms';

@Component({
  selector: 'core-input-password',
  templateUrl: 'input-password.component.html',
  styleUrls: ['input-password.component.scss', '../core-input.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: InputPasswordComponent,
    multi: true
  }]
})
export class InputPasswordComponent implements ControlValueAccessor, OnInit {

  @Input() id: string;
  @Input() label: string;
  @Input() floatLabel: string;
  @Input() topLabel: string;
  @Input() placeholder: string = '';
  @Input() showClearButton: boolean = true;

  @Input() enableBrowserSuggestion: boolean = false;

  @Input() removeDefaultBottomErrorMargin: boolean = false;

  @Output() onKeyUpEnter = new EventEmitter<string>();

  control: NgControl;

  value: string;
  disabled = false;
  private onTouched: Function;
  private onChanged: Function;

  constructor(private inj: Injector) {
    if (!this.id) {
      this.id = 'core-input-password-' + Math.random();
    }
  }

  public static matchValues(matchTo: string): (a: AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
      return !!control.parent &&
      !!control.parent.value &&
      // @ts-ignore
      control.value === control.parent.controls[matchTo].value ? null : {doesNotMatch: true};
    };
  }

  public static minOneLowerCaseCharacter(): (a: AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
      return !!control.value &&
      // @ts-ignore
      control.value.toUpperCase() !== control.value ? null : {noLowerCaseCharacter: true};
    };
  }

  public static minOneUpperCaseCharacter(): (a: AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
      return !!control.value &&
      // @ts-ignore
      control.value.toLowerCase() !== control.value ? null : {noUpperCaseCharacter: true};
    };
  }

  public static minOneNumberCaseCharacter(): (a: AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
      return !!control.value &&
      // @ts-ignore
      /\d/.test(control.value) ? null : {noNumber: true};
    };
  }

  onClear() {
    this.valueChanged(null);
    this.onKeyUpEnter.emit(null)
  }

  ngOnInit() {
    this.control = this.inj.get(NgControl)
  }

  valueChanged(value: string) {
    this.onTouched();
    this.value = value;
    this.onChanged(value);
  }

  writeValue(value: string): void {
    this.value = value;
  }

  registerOnChange(fn: Function): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: Function): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

}
