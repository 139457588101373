import {Component, Injector, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl} from '@angular/forms';
import {InputButtonIconType} from '../../button/button.utils';
import {FileService} from '../../../../services/rest/file.service';
import {FileCreate} from '../../../../dtos/file/FileCreate';
import {MessageService} from '../../../../services/message.service';
import {File} from '../../../../dtos/file/File';

@Component({
  selector: 'core-input-file-upload',
  templateUrl: 'input-file-upload.component.html',
  styleUrls: ['input-file-upload.component.scss', '../core-input.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: InputFileUploadComponent,
    multi: true
  }]
})
export class InputFileUploadComponent implements ControlValueAccessor, OnInit {

  URL = URL;
  InputButtonIconType = InputButtonIconType;

  @Input() id: string;
  @Input() label: string;
  @Input() floatLabel: string;
  @Input() topLabel: string;
  @Input() placeholder: string = '';
  @Input() chooseLabel: string = 'Browse';
  @Input() showClearButton: boolean = true;

  @Input() removeDefaultBottomErrorMargin: boolean = false;

  dropOverActive: boolean = false;

  hasItemFocus = false;
  control: NgControl;
  value: File;
  disabled = false;
  private onTouched: Function;
  private onChanged: Function;

  constructor(private inj: Injector, private fileService: FileService, private messageService: MessageService) {
    if (!this.id) {
      this.id = 'core-input-file-upload-' + Math.random();
    }
  }

  dropOver(event: boolean) {
    this.dropOverActive = event;
  }

  ngOnInit() {
    this.control = this.inj.get(NgControl);
  }

  // is a list of Files, it is only a single upload possible, therefore use the first in the list
  onUploadInput(target: any) {
    if (target && target.files) {
      this.onUpload(target.files);
    }
  }

  // type is a ts File
  onUpload(files: any[]) {
    this.value = files[0];
    // it is instantly saved in the backend
    // this save will return a resource url which can be used
    // FileUtils.getRepresentableImageFromFile(file).then(resp => this.url = resp);
    const fileToCreate = new FileCreate();
    fileToCreate.content = this.value;
    fileToCreate.file = {name: this.value.name, description: this.value.description};
    this.fileService.createFile(fileToCreate).subscribe(resp => {
      this.messageService.addSuccessMessage('Datei erfolgreich hochgeladen!');
      this.valueChanged(resp);
    });
  }

  onRemove(file: File) {
    this.value = null;
  }

  onClear() {
    this.valueChanged(null);
  }

  valueChanged(value: File) {
    this.onTouched();
    this.value = value;
    this.onChanged(value);
  }

  writeValue(value: File): void {
    this.value = value;
  }

  registerOnChange(fn: Function): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: Function): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

}
