import {NgModule} from '@angular/core';
import {GroupTableComponent} from './table/group-table.component';
import {SharedModule} from '../../shared/shared.module';
import {GroupEditComponent} from './edit/group-edit.component';
import {GroupCreateComponent} from './create/group-create.component';

@NgModule({
  declarations: [
    GroupTableComponent,
    GroupCreateComponent,
    GroupEditComponent
  ],
  imports: [
    SharedModule
  ],
  exports: [
    GroupTableComponent,
    GroupCreateComponent,
    GroupEditComponent
  ]
})
export class GroupModule {

}
