import {NgModule} from '@angular/core';
import {TopMenuComponent} from './top-menu.component';
import {RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {TooltipModule} from 'primeng/tooltip';
import {FaIconLibrary, FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {fas} from '@fortawesome/free-solid-svg-icons';
import {far} from '@fortawesome/free-regular-svg-icons';
import {CoreInputModule} from '../../input/core-input.module';
import {FormsModule} from '@angular/forms';
import {PipesModule} from '../../../../pipes/pipes.module';

@NgModule({
  declarations: [TopMenuComponent],
  imports: [
    CommonModule,
    CoreInputModule,
    FontAwesomeModule,
    FormsModule,
    OverlayPanelModule,
    RouterModule,
    TooltipModule,
    PipesModule
  ],
  exports: [TopMenuComponent],
  providers: []
})
export class TopMenuModule {
  constructor(library: FaIconLibrary) {
    // Add icon-collections to library
    library.addIconPacks(fas);
    library.addIconPacks(far);
  }

}
