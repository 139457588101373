import {NgModule} from '@angular/core';
import {DashboardComponent} from './dashboard.component';
import {ChartModule} from 'primeng/chart';
import {CardModule} from 'primeng/card';
import {DropdownModule} from 'primeng/dropdown';
import {SharedModule} from '../../shared/shared.module';
import {CoreChartModule} from '../core/charts/core-chart.module';

@NgModule({
  declarations: [DashboardComponent],
  imports: [
    ChartModule,
    CardModule,
    DropdownModule,
    SharedModule,
    CoreChartModule
  ],
  exports: [DashboardComponent]
})
export class DashboardModule {

}
