import {NgModule} from '@angular/core';
import {SideMenuModule} from './side-menu/side-menu.module';
import {TopMenuComponent} from './top-menu/top-menu.component';
import {TopMenuModule} from './top-menu/top-menu.module';

@NgModule({
  declarations: [],
  imports: [SideMenuModule, TopMenuModule],
  exports: [SideMenuModule, TopMenuModule],
  providers: []
})
export class CoreMenuModule {

}
