<div class="core-edit-max-width">

  <div class="flex-row-space-between">

    <h1>Organisation bearbeiten</h1>

  </div>

  <form *ngIf="organization && form" [formGroup]="form">

    <core-input-file-upload
      *ngIf="!form.controls.logo.value"
      formControlName="logo"
      label="Logo">
    </core-input-file-upload>

    <core-input-file-viewer
      *ngIf="form.controls.logo.value"
      [showDetails]="false"
      formControlName="logo"
      label="Logo">
    </core-input-file-viewer>

    <core-input-text
      formControlName="name"
      id="name"
      label="Name">
    </core-input-text>

    <core-input-dropdown
      [options]="timezones"
      formControlName="timezone"
      id="timezone"
      label="Zeitzone">
    </core-input-dropdown>

    <core-input-multiselect
      [options]="languages"
      [showIconClass]="true"
      formControlName="languages"
      id="languages"
      label="Sprachen">
    </core-input-multiselect>

    <core-button-submit
      (onClick)="submitChanges()"
      [disabled]="form.invalid || form.disabled"
      id="button_submit">
    </core-button-submit>

  </form>

</div>
