<div class="h-100" style="display: flex; flex-direction: column">
  <div class="flex-row-space-between">
    <h1>Kundenprojekte</h1>
    <div>
      <ng-container *ngFor="let option of locations">
        <div
          style="display: flex; flex-direction: column; justify-content: center; align-items: center; margin: 1px;">
          <p-chip
            [label]="option"
            [removable]="true"
            styleClass="p-mr-2 multiselect-chip">
          </p-chip>
        </div>
      </ng-container>
    </div>
    <div></div>
    <core-button-icon-big
      (click)="onCreateProject()"
      *ngIf="userCanEdit"
      [type]="InputButtonIconType.ADD"
      id="button-create-user">
    </core-button-icon-big>
  </div>
  <div *ngIf="false" class="row" style="margin-bottom: 15px;">
    <core-input-email
      (onKeyUpEnter)="onSearch()"
      [(ngModel)]="searchString"
      [removeDefaultBottomErrorMargin]="true"
      class="col-10"
      id="email"
      placeholder="E-Mail">
    </core-input-email>
    <core-button
      (click)="onSearch()"
      [icon]="['fas', 'search']"
      [width]="'100%'"
      class="col-2"
      id="search"
      text="Suchen">
    </core-button>
  </div>
  <div class="project-grid">
    <div class="full-width-column">
      <div class="project-card">
        <div class="grid-header">
          <div></div>
          <div>Projekt</div>
          <div class="text-center">Standort</div>
          <div class="text-center">Status</div>
          <div class="text-center" style="padding-right: 10px">Fertigstellung</div>
          <div class="text-center" style="padding-right: 10px">Verwendetes Budget</div>
        </div>
        <ng-container *ngFor="let project of projects">
          <app-project-card [project]="project" style="width: 100%"></app-project-card>
        </ng-container>
      </div>
    </div>
    <div *ngIf="false" class="full-width-column indented-column">
      <h1>Interne Projekte</h1>
      <ng-container *ngFor="let project of projects">
        <app-project-card [project]="project" style="width: 100%"></app-project-card>
      </ng-container>
    </div>
  </div>
</div>
