import {Injectable, Type} from '@angular/core';
import {DialogService as PDialogService, DynamicDialogRef} from 'primeng/dynamicdialog';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(public dialogService: PDialogService) {
  }

  /**
   * create a new confirm dialog which the user has to either accept or reject
   *
   * @param componentType type of the component rendered inside the dialog
   * @param header name of the dialog
   * @param onClose (optional) callback function which is triggered when the user closes the dialog
   * @param width (optional) default value is 70%
   * @param contentStyle (optional) default value is {"overflow": "auto"}
   * @param maximizable (optional) default value is false
   */
  create(componentType: Type<any>,
         header: string,
         onClose: (val: any) => void = () => {
         },
         width: string = '35%',
         contentStyle: any = {"overflow": "auto"},
         maximizable: boolean = false) {
    const ref: DynamicDialogRef = this.dialogService.open(componentType,
      {
        header: header,
        width: width,
        contentStyle: contentStyle,
        baseZIndex: 100000,
        maximizable: maximizable
      });

    ref.onClose.subscribe((val: any) => {
      if (onClose != null) {
        onClose(val);
      }
      // destroy all ui elements in the dialog
      ref.destroy();
    });

    // not needed for now
    // this.ref.onMaximize.subscribe((value: any) => console.log(value));

  }

}
