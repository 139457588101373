<div class="projects-card">

  <div
    [ngStyle]="{'background-color': getFontColor(project)}" class="sidebar"></div>

  <div class="content">
    <div (click)="project.expanded = !project.expanded" class="header">
      <div class="project-code">P000{{project.id}}</div>

      <div class="project-name-column">
        <div class="project-name">{{project.name}}</div>
        <div class="project-client-name">
          <fa-icon [icon]="['fas', 'link']"></fa-icon>
          {{project.clientname}}
        </div>
      </div>

      <div class="align-center">{{project.location}}</div>
      <div class="align-center">{{project.status}}</div>
      <div class="align-center">
        <div
          [ngClass]="{'color-red': project.endDate < today, 'color-green': project.endDate >= today}"
          style="display: flex; justify-content: center; align-items: end">
          <ng-container *ngIf="project.endDate > today">in</ng-container>
          <ng-container *ngIf="project.endDate < today">vor</ng-container>
          <div style="font-size: 24px; margin: 0 3px -5px 3px;"> {{calculateDays()}} </div>
          Tagen
        </div>
        <div *ngIf="false" style="font-size: 12px">{{project.endDate | date: 'dd.MM.yyyy'}}</div>
      </div>

      <div style="display: flex; justify-content: center; align-items: center">
        <core-doughnut-budget-indicator
          [size]="60"
          [color]="getFontColor(project)"
          [percentage]="calculateBudgetPercentage(project)">
        </core-doughnut-budget-indicator>
      </div>

      <div class="align-right">
        <fa-icon *ngIf="!project.expanded" [icon]="['fas', 'chevron-right']" style="font-size: 18px"></fa-icon>
        <fa-icon *ngIf="project.expanded" [icon]="['fas', 'chevron-down']" style="font-size: 18px"></fa-icon>
      </div>
    </div>

    <div *ngIf="project.expanded" class="table-wrapper">
      <div style="display: flex; flex-direction: column;">
        <h3 class="header-font-size">Materialbuchungen</h3>
        <div style="width: 100%">
          <div class="employee table-header">
            <div>Gruppe</div>
            <div>Version</div>
            <div class="align-right">gebucht</div>
            <div class="align-right">geplant</div>
            <div class="align-right">gebucht</div>
            <div class="align-right">geplant</div>
          </div>
          <div *ngFor="let material of project.material"
               [ngClass]="{'red-gradient': material.booked > material.assigned, 'green-gradient': material.booked <= material.assigned}"
               class="employee">
            <div>{{material.type}}</div>
            <div>{{material.name}}</div>
            <div
              [ngClass]="{'color-red': material.booked > material.assigned, 'color-green': material.booked <= material.assigned}"
              class="align-right">{{material.booked}}
              <ng-container *ngIf="material.booked">Stk.</ng-container>
            </div>
            <div class="align-right">{{material.assigned}}
              <ng-container *ngIf="material.assigned">Stk.</ng-container>
            </div>
            <div
              [ngClass]="{'color-red': material.booked > material.assigned, 'color-green': material.booked <= material.assigned}"
              class="align-right">{{material.bookedCosts | currency:'EUR':'€':'1.2-2':'de-DE' }}</div>
            <div class="align-right">{{material.assignedCosts | currency:'EUR':'€':'1.2-2':'de-DE' }}</div>
          </div>
          <div class="employee table-header">
            <div></div>
            <div>Gesamt:</div>
            <div [ngClass]="{'color-red': true, 'color-green': false}" class="align-right">17 Stk.</div>
            <div class="align-right">15 Stk.</div>
            <div [ngClass]="{'color-red': true, 'color-green': false}"
                 class="align-right">{{10650 | currency:'EUR':'€':'1.2-2':'de-DE' }}</div>
            <div class="align-right">{{10500 | currency:'EUR':'€':'1.2-2':'de-DE' }}</div>
          </div>
        </div>
      </div>
      <div style="display: flex; flex-direction: column;">
        <h3 class="header-font-size">Personalbuchungen</h3>
        <div style="width: 100%">
          <div class="employee table-header">
            <div>Name</div>
            <div>Position</div>
            <div class="align-right">gebucht</div>
            <div class="align-right">geplant</div>
            <div class="align-right">gebucht</div>
            <div class="align-right">geplant</div>
          </div>
          <div *ngFor="let employee of project.employees"
               [ngClass]="{'red-gradient': employee.booked > employee.assigned, 'green-gradient': employee.booked <= employee.assigned}"
               class="employee">
            <div>{{employee.firstname}} {{employee.lastname}}</div>
            <div>{{employee.position}}</div>
            <div
              [ngClass]="{'color-red': employee.booked > employee.assigned, 'color-green': employee.booked <= employee.assigned}"
              class="align-right">{{employee.booked}}
              h
            </div>
            <div class="align-right">{{employee.assigned}} h</div>
            <div
              [ngClass]="{'color-red': employee.booked > employee.assigned, 'color-green': employee.booked <= employee.assigned}"
              class="align-right">{{employee.bookedCosts | currency:'EUR':'€':'1.2-2':'de-DE' }}</div>
            <div class="align-right">{{employee.assignedCosts | currency:'EUR':'€':'1.2-2':'de-DE' }}</div>
          </div>
          <div class="employee table-header">
            <div></div>
            <div>Gesamt:</div>
            <div [ngClass]="{'color-red': true, 'color-green': false}" class="align-right">75 h</div>
            <div class="align-right">65 h</div>
            <div [ngClass]="{'color-red': true, 'color-green': false}"
                 class="align-right">{{1500 | currency:'EUR':'€':'1.2-2':'de-DE' }}</div>
            <div class="align-right">{{850 | currency:'EUR':'€':'1.2-2':'de-DE' }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
