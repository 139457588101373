import {EnumUtils} from './utils/EnumUtils';

export enum LanguageEnum {
  GERMAN = 'de'
}

export namespace LanguageEnum {
  export function getAll(): LanguageEnum[] {
    return EnumUtils.getAll<LanguageEnum>(LanguageEnum, 'string');
  }

  export function getEnumByValue(value: string): LanguageEnum {
    return EnumUtils.getEnumByValue<LanguageEnum>(LanguageEnum, 'string', value);
  }

  export function getAllDetailed(): { lang: string, name: string, iconClass: string }[] {
    return [
      {lang: 'de', name: 'GERMAN', iconClass: 'fi fi-de'},
    ];
  }

  export function getIconClassByLang(lang: string): string {
    return LanguageEnum.getAllDetailed().find(t => t.lang === lang)?.iconClass;
  }

  export function getDefaultLang(): string {
    return LanguageEnum.GERMAN;
  }

}
