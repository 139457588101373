<div class="core-edit-max-width">

  <h1>Benutzer erstellen</h1>

  <form *ngIf="form" [formGroup]="form">

    <core-input-file-upload
      *ngIf="!form.controls.image.value"
      formControlName="image"
      label="Profilbild">
    </core-input-file-upload>

    <core-input-file-viewer
      *ngIf="form.controls.image.value"
      [height]="'80px'"
      [showDetails]="false"
      formControlName="image"
      label="Profilbild">
    </core-input-file-viewer>

    <core-input-text
      formControlName="firstName"
      id="firstName"
      label="Vorname">
    </core-input-text>

    <core-input-text
      formControlName="lastName"
      id="lastName"
      label="Nachname">
    </core-input-text>

    <core-input-text
      formControlName="title"
      id="title"
      label="Titel">
    </core-input-text>

    <core-input-email
      formControlName="email"
      id="email"
      label="E-Mail">
    </core-input-email>

    <core-input-password
      formControlName="password"
      id="password"
      label="Passwort">
    </core-input-password>

    <core-input-password
      formControlName="confirmPassword"
      id="confirmPassword"
      label="Passwort bestätigen">
    </core-input-password>

    <core-input-dropdown
      [binary]="true"
      formControlName="locked"
      id="locked"
      label="Gesperrt">
    </core-input-dropdown>

    <core-input-multiselect
      [options]="allGroups"
      formControlName="groups"
      id="groups"
      label="Gruppen">
    </core-input-multiselect>

    <core-input-multiselect
      [options]="allPermissions"
      formControlName="permissions"
      id="permissions"
      label="Berechtigungen">
    </core-input-multiselect>

    <core-button-submit
      (onClick)="submitChanges()"
      [disabled]="form.invalid || form.disabled"
      id="button_submit">
    </core-button-submit>

  </form>

</div>
