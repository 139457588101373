import {Sort} from './Sort';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {SortDirectionEnum} from './SortDirectionEnum';

export class TableSortExtension {

  // is called everytime the sort is changed by the user
  onSortOrderChangedCallback: (Function);

  // apply timeout when the user changes the current sort order
  // can be null
  timeoutOnSortChanged: number;

  // timeout used if timeoutOnSortChanged != null
  onSortOrderChangedCallbackTimeout: number;

  defaultSortOrder: SortDirectionEnum = SortDirectionEnum.ASC;

  // current sort order
  sortList: Sort[] = [];

  setOnSortOrderChangedCallbackFunction(onSortOrderChangedCallback: Function) {
    this.onSortOrderChangedCallback = onSortOrderChangedCallback;
  }

  setTimeoutOnSortChanged(timeoutOnSortChanged: number) {
    this.timeoutOnSortChanged = timeoutOnSortChanged;
  }

  setDefaultSortOrder(defaultSortOrder: SortDirectionEnum) {
    this.defaultSortOrder = defaultSortOrder;
  }

  getSortIconByFieldName(fieldName: string): IconProp {
    const sort = this.sortList.find(s => s.fieldName === fieldName);
    if (!sort) {
      // return sortable icon
      return ['fas', 'sort'];
    } else if (sort.direction === 'ASC') {
      // return ASC icon
      return ['fas', 'sort-alpha-down'];
    } else if (sort.direction === 'DESC') {
      // return DESC icon
      return ['fas', 'sort-alpha-up-alt'];
    }
    return null;
  }

  // returns index of the sort list or null if it is not in the list (starts with 1)
  getSortIndexByFieldName(fieldName: string): number | null {
    const index = this.sortList.findIndex(s => s.fieldName === fieldName);
    if (index < 0) {
      return null;
    } else {
      return index + 1;
    }
  }

  onSortOrderChanged(fieldName: string) {
    if (!fieldName) {
      return;
    }

    // stop current timeout
    this.onSortOrderChangedCallbackTimeout = null;

    // change or add entry into sort list
    const sort = this.sortList.find(s => s.fieldName === fieldName);
    if (!sort) {
      // add new entry into sort list
      this.sortList.push(new Sort(fieldName, this.defaultSortOrder));
    } else if (sort.direction === this.defaultSortOrder) {
      // change sort direction
      sort.direction = SortDirectionEnum.getOpposite(sort.direction);
    } else if (sort.direction === SortDirectionEnum.getOpposite(this.defaultSortOrder)) {
      // remove from sort list
      this.sortList.splice(this.sortList.findIndex(s => s === sort), 1);
    }

    // if there is a timeout configured start it
    if (this.timeoutOnSortChanged && this.timeoutOnSortChanged > 0) {
      this.onSortOrderChangedCallbackTimeout = setTimeout(() => {
        this.onSortOrderChangedCallback();
      }, this.timeoutOnSortChanged)
    } else {
      // else run the callback function without a timeout
      this.onSortOrderChangedCallback();
    }
  }

  // sorts the list of values based on the sort list in the frontend
  sortInFrontend(list: any[]) {
    if (!list || list.length <= 0 || !this.sortList) {
      return;
    }
    const sortArray = [].concat(this.sortList);
    // set last order to id to get a consistent result!
    sortArray.push({fieldName: 'id', direction: SortDirectionEnum.DESC});
    list.sort((a: any, b: any) => {
      for (const sort of sortArray) {
        // normally string comparison is sufficient, not for Boolean
        if (typeof a[sort.fieldName] === 'boolean') {
          if (a[sort.fieldName] && b[sort.fieldName]
            || !a[sort.fieldName] && !b[sort.fieldName]) {
            continue;
          }
          return a[sort.fieldName] ?
            sort.direction === SortDirectionEnum.ASC ? -1 : 1 :
            sort.direction === SortDirectionEnum.ASC ? 1 : -1;
        }
        // number comparison
        if (typeof a[sort.fieldName] === 'number') {
          if (sort.direction === SortDirectionEnum.ASC) {
            return this.numberCompare(b[sort.fieldName], a[sort.fieldName]);
          } else {
            return this.numberCompare(a[sort.fieldName], b[sort.fieldName]);
          }
        }
        // string comparison
        if (!a[sort.fieldName] && !b[sort.fieldName])
          continue;
        if (!a[sort.fieldName] && b[sort.fieldName])
          return sort.direction === SortDirectionEnum.ASC ? -1 : 1;
        if (a[sort.fieldName] && !b[sort.fieldName])
          return sort.direction === SortDirectionEnum.ASC ? 1 : -1;
        const aString: string = a[sort.fieldName].toString();
        const bString: string = b[sort.fieldName].toString();
        const compare = aString ? aString.localeCompare(bString) : 0;
        if (compare !== 0)
          return sort.direction === SortDirectionEnum.ASC ? compare : compare * (-1);
      }
      return 0;
    });
  }

  public numberCompare(a: number, b: number): number {
    if (a == null || b == null) return -1;
    return (a < b) ? -1 : ((a == b) ? 0 : 1);
  }

}
