export class EnumUtils {

  /**
   * @param classOfEnum which contains the enums
   * @param enumType of the enum values -> can either be a number or a string
   * @param value of the enum which should be returned
   * @return List of all enums found in object
   */
  static getEnumByValue<T>(classOfEnum: any, enumType: string, value: string): T {
    if (value == null)
      return null;
    const allEnums: any[] = EnumUtils.getAll<T>(classOfEnum, enumType);
    for (const enumVal of allEnums)
      if (enumVal && enumVal == value)
        return enumVal;
    return null;
  }

  /**
   * @param classOfEnum which contains the enums
   * @param enumType of the enum values -> can either be a number or a string
   * @return List of all enums found in object
   */
  static getAll<T>(classOfEnum: any, enumType: string): T[] {
    if (enumType != 'number' && enumType != 'string')
      return [];
    const keys: string[] = Object.keys(classOfEnum);
    const result: T[] = [];
    for (const key of keys)
      if (typeof classOfEnum[key] == enumType)
        result.push(classOfEnum[key] as T);
    return result;
  }
}
