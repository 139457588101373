<div style="display: flex; flex-direction: row;">
  <div [style.height.px]="size" [style.width.px]="size" class="doughnut-wrapper">
    <p-chart [data]="data" [height]="size + 'px'" [options]="options" [width]="size + 'px'" type="doughnut"></p-chart>
    <div [ngStyle]="getLabelStyle()" class="doughnut-text">
      {{getPercentageReadable()}}
    </div>
  </div>
  <div *ngIf="showText" [ngStyle]="{'color': color}" style="display: flex; align-items: center; margin-left: 5px;">
    <ng-container *ngIf="percentage <= 80"> Planmäßig</ng-container>
    <ng-container *ngIf="percentage > 80 && percentage <= 100">Ausgereizt</ng-container>
    <ng-container *ngIf="percentage > 100"> Überzogen</ng-container>
  </div>
</div>
