<div class="core-edit-max-width">

  <div class="flex-row-space-between">

    <h1>Datei bearbeiten</h1>

    <core-button-slight
      (click)="onDeleteFile(file)"
      *ngIf="form"
      [disabled]="form.disabled"
      [type]="InputButtonIconType.DELETE">
    </core-button-slight>

  </div>

  <form *ngIf="file && form" [formGroup]="form">

    <core-input-file-viewer
      [showClearButton]="false"
      formControlName="file">
    </core-input-file-viewer>

    <core-input-text
      formControlName="name"
      id="name"
      label="Name">
    </core-input-text>

    <core-input-text
      formControlName="description"
      id="description"
      label="Beschreibung">
    </core-input-text>

    <core-button-submit
      (onClick)="submitChanges()"
      [disabled]="form.invalid || form.disabled"
      id="button_submit">
    </core-button-submit>

  </form>

</div>
