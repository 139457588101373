import {Sort} from '../components/core/table/sort/Sort';

export class PageRequest {

  constructor(public size: number,
              public page: number,
              public sortList: Sort[]) {
  }

}
