import {Injectable, Injector} from '@angular/core';
import {Observable, of} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import jwt_decode from 'jwt-decode';
import {Globals} from '../../global/globals';
import {AuthenticationRequest} from '../../dtos/authentication/AuthenticationRequest';
import {SimplePermission} from '../../dtos/permission/SimplePermission';
import {PermissionActionEnum} from '../../enums/PermissionActionEnum';
import {PermissionTargetEnum} from '../../enums/PermissionTargetEnum';
import {Router} from '@angular/router';
import {DataService} from '../data.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private authBaseUri: string = this.globals.backendUri + '/authentication';

  private dataService: DataService;

  constructor(private httpClient: HttpClient,
              private globals: Globals,
              private router: Router,
              injector: Injector) {
    setTimeout(() => this.dataService = injector.get(DataService));
  }

  /**
   * Login in the user. If it was successful, a valid JWT token will be stored
   *
   * @param authRequest User data
   */
  async loginUser(authRequest: AuthenticationRequest): Promise<Observable<string>> {
    console.log('Login attempt for user ' + authRequest.email);
    const token = await this.httpClient.post(this.authBaseUri, authRequest, {responseType: 'text'}).toPromise();
    if (!token) {
      throw new Error('Login was not successful!');
    }
    console.log('Login successful for user ' + authRequest.email);
    this.setToken(token);
    // also set user in data service and wait for it to finish
    await this.dataService.onUserLogIn();
    console.log('done')
    return of<string>(token);
  }

  /**
   * check if a user is logged in
   * return true if a token is present and the token is not expired
   */
  isLoggedIn(): boolean {
    return this.getToken() && this.getTokenExpirationDate(this.getToken()).valueOf() > new Date().valueOf();
  }

  /**
   * return time until the jwt token expires
   * 0 if the token is already expired
   */
  getTokenExpirationInMilliSeconds(): number {
    const value = this.getTokenExpirationInMilliSecondsOrNull(this.getToken());
    return !value ? 0 : value;
  }

  /**
   * get the token from the local storage
   */
  getToken() {
    return JSON.parse(JSON.stringify(localStorage.getItem('token')));
  }

  /**
   * get the userId from the token
   */
  getUserIdFromToken(): number {
    const decoded: any = jwt_decode(this.getToken());
    return decoded.userId;
  }

  /**
   * get the username from the token
   */
  getUserNameFromToken(): string {
    const decoded: any = jwt_decode(this.getToken());
    return decoded.sub;
  }

  /**
   * get all permissions from the token
   */
  getPermissionsFromToken(): SimplePermission[] {
    if (this.getToken() != null) {
      const decoded: any = jwt_decode(this.getToken());
      const permissionStrings: string = decoded.rol.split(',');
      const permissions: SimplePermission[] = [];
      for (const p of permissionStrings) {
        const action = p.split(':')[0];
        const target = p.split(':')[1];
        permissions.push(
          new SimplePermission(
            PermissionActionEnum.getEnumByValue(action),
            PermissionTargetEnum.getEnumByValue(target)));
      }
      return permissions;
    }
    return [];
  }

  /**
   * store the token into the local storage
   *
   * @param token
   */
  setToken(token: string) {
    localStorage.setItem('token', token);
  }

  /**
   * logout user
   * clear the local storage and redirect the user to the login page
   */
  logoutUser() {
    console.log('Logout');
    localStorage.removeItem('token');
    this.dataService.onUserLogOut()
    this.router.navigate(['/login']);
  }

  removeTokenFromLocalStorageAndRedirectToLoginPage() {
    console.log('Logout');
    localStorage.removeItem('token');
    this.router.navigate(['/login']);
  }

  private getTokenExpirationDate(token: string): Date | null {
    const decoded: any = jwt_decode(token);
    if (decoded.exp === undefined) {
      return null;
    }
    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);
    return date;
  }

  private getTokenExpirationInMilliSecondsOrNull(token: string): number | null {
    const date = this.getTokenExpirationDate(token);
    if (!date) {
      return null;
    }
    return date.getTime() - new Date().getTime();
  }

}
