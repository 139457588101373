import {NgModule} from '@angular/core';
import {DropZoneDirective} from './drop-zone.directive';

@NgModule({
  declarations: [DropZoneDirective],
  imports: [],
  exports: [DropZoneDirective]
})
export class CoreDirectiveModule {

}
