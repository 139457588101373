<p-toast
  [style]="{width: '330px', 'z-index': 10000}"
  position="top-right">
</p-toast>

<div style="width: 100%; height: 100%; display: flex; flex-direction: column">

  <core-top-menu
    *ngIf="userLoggedIn()"
    [appInfo]="appInfo"
    [username]="getUserNameFromToken()">
  </core-top-menu>

  <div style="width: 100%; height: 100%; display: flex; flex-direction: row">

    <core-side-menu
      class="h-100"
      *ngIf="showSideMenu()">
    </core-side-menu>

    <div class="container h-100" style="padding: 20px;">
      <router-outlet>
      </router-outlet>
    </div>

  </div>

</div>

<p-confirmDialog
  #confirmDialog
  [closable]=false
  [closeOnEscape]=false
  key="confirmDialog">
</p-confirmDialog>
