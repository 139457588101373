import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

interface Timezone {
  value: number;
  name: {
    [key: string]: string;
  };
}

@Injectable({
  providedIn: 'root'
})
export class TimezoneService {

  private timezonesUrl = 'assets/timezones.json';

  constructor(private http: HttpClient) {
  }

  getTimezones(): Observable<Timezone[]> {
    return this.http.get<Timezone[]>(this.timezonesUrl);
  }

}
