<label *ngIf="topLabel" [for]="id" class="core_input_top_label">{{topLabel}}</label>
<div class="core-input-label-container">
  <label *ngIf="label">{{label}}</label>
  <div [ngClass]="floatLabel ? 'core-input-container-float-label p-float-label' : ''" class="core-input-container">
    <ng-container *ngFor="let val of translations">
      <div class="p-inputgroup core-input-container core_input_flex_between core-input-label-container">
        <label style="width: 30%; padding-left: 10px">{{val.key}}</label>
        <input
          (keyup.enter)="onKeyUpEnter.emit(val.value)"
          (ngModelChange)="valueChanged(val.key, $event)"
          [disabled]="disabled"
          [id]="id"
          [ngClass]="control.touched && val.errors ? 'core_input_invalid' : ''"
          [ngModel]="val.value"
          [placeholder]="placeholder"
          [style]="{width: showClearButton ? 'calc(100% - 40px)' : '100%'}"
          class="core-input"
          pInputText
          type="text">
        <label *ngIf="floatLabel" [for]="id" class="core_input_float_label">{{floatLabel}}</label>
        <button
          (click)="onClear(val.key)"
          *ngIf="showClearButton"
          [disabled]="disabled"
          class="p-button-secondary core-input-button-clear p-inputgroup-addon"
          icon="pi pi-times"
          pButton
          tabindex="-1">
        </button>
      </div>
      <div
        [ngClass]="[label ? 'core_input_error_container_with_label' : 'core_input_error_container_with_float_label', removeDefaultBottomErrorMargin ? '' : 'core_input_error_container_min_height']"
        class="core_input_error">
        <small *ngIf="control.touched && val.errors?.required">
          Dieses Feld ist erforderlich!
        </small>
        <small *ngIf="control.touched && val.errors?.minlength">
          Dieses Feld muss mindestens {{val.errors.minlength.requiredLength}} Zeichen lang sein!
        </small>
        <small *ngIf="control.touched && val.errors?.maxlength">
          Dieses Feld darf maximal {{val.errors.maxlength.requiredLength}} Zeichen lang sein!
        </small>
      </div>
    </ng-container>
  </div>
</div>
