import {Pipe, PipeTransform} from '@angular/core';
import {TranslationService} from '../services/rest/translation.service';
import {Translation} from '../dtos/translation/Translation';

@Pipe({
  name: 'translate'
})
export class TranslationPipe implements PipeTransform {

  constructor(private translationService: TranslationService) {
  }

  transform(value: string | Translation): string {
    return this.translationService.translate(value);
  }

}

