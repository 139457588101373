import {Component, EventEmitter, Injector, Input, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl} from '@angular/forms';

@Component({
  selector: 'core-input-money',
  templateUrl: 'input-money.component.html',
  styleUrls: ['input-money.component.scss', '../core-input.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: InputMoneyComponent,
    multi: true
  }]
})
export class InputMoneyComponent implements ControlValueAccessor, OnInit {

  @Input() id: string;
  @Input() label: string;
  @Input() floatLabel: string;
  @Input() topLabel: string;
  @Input() placeholder: string = '';
  @Input() showClearButton: boolean = true;

  @Input() removeDefaultBottomErrorMargin: boolean = false;

  @Input() min: number = 0;
  @Input() max: number = Number.POSITIVE_INFINITY;

  @Input() maxFractionDigits: number = 2;
  @Input() minFractionDigits: number = 2;

  // this only impacts the key up and key down value changes
  @Input() step: number = 1;

  @Output() onKeyUpEnter = new EventEmitter<number>();

  control: NgControl;

  value: number;
  disabled = false;
  private onTouched: Function;
  private onChanged: Function;

  constructor(private inj: Injector) {
    if (!this.id) {
      this.id = 'core-input-number-' + Math.random();
    }
  }

  onClear() {
    this.valueChanged(null);
    this.onKeyUpEnter.emit(null)
  }

  ngOnInit() {
    this.control = this.inj.get(NgControl)
  }

  valueChanged(value: number) {
    // check if value is inside min/max range
    if (value < this.min) {
      value = this.min;
    }
    if (value > this.max) {
      value = this.max;
    }
    this.onTouched();
    this.value = value;
    this.onChanged(value);
  }

  writeValue(value: number): void {
    this.value = value;
  }

  registerOnChange(fn: Function): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: Function): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

}
