import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';

@Component({
  selector: 'core-doughnut-budget-indicator',
  templateUrl: 'doughnut-budget-indicator.component.html',
  styleUrls: ['doughnut-budget-indicator.component.scss']
})
export class DoughnutBudgetIndicatorComponent implements OnInit, OnChanges {
  Math = Math;

  @Input() showText: boolean = true;
  @Input() percentage: number;
  @Input() color: string;
  @Input() size: number;

  data: any;
  options: any;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.percentage) {
      this.init();
    }
  }

  degreesToRadians(degrees: number): number {
    return degrees * (Math.PI / 180);
  }

  getPercentageReadable(): string {
    return this.percentage > 100 ? '100+' : this.percentage + '';
  }

  getLabelStyle(): any {
    return {
      'color': this.color,
      'font-size.px': (this.size / 60) * 17,
      'top': 'calc(50% - ' + (this.size / 60) * 12 + 'px)'
    };
  }

  ngOnInit() {
    this.init();
  }

  init() {
    const color = this.color;
    this.data = {
      labels: ['', '', ''],
      datasets: [
        {
          data: [400 * (Math.min(this.percentage, 100) / 100), 400 * (1 - (Math.min(this.percentage, 100) / 100)), 100],
          backgroundColor: [
            color,
            "#A6A6A683",
            "transparent"
          ],
          hoverBackgroundColor: [
            color,
            "#A6A6A683",
            "transparent"
          ]
        }],
      tooltip: [{tooltipItems: ''}, {tooltipItems: ''}, {tooltipItems: ''},]
    };

    this.options = {
      rotation: 3 * Math.PI / 2, // Starts the chart from the bottom center
      animation: {
        animateRotate: true
      },
      cutout: '70%',
      title: {
        display: true,
        text: 'My Doughnut Chart',
        fontSize: 16
      },
      plugins: {
        legend: {display: false},
        tooltip: {
          enabled: false
        }
      },
      hover: {
        mode: null
      },
    };
  }

}
