<label *ngIf="topLabel" [for]="id" class="core_input_top_label">{{topLabel}}</label>
<div
  class="core-input-label-container"
  style="position: relative; align-items: end;">
  <label *ngIf="label">{{label}}</label>
  <div [ngClass]="floatLabel ? 'core-input-container-float-label p-float-label' : ''"
       class="p-inputgroup core-input-container core_input_flex_between">

    <div [ngClass]="{'border-radius-bottom-right': showClearButton, 'border': showBorder}" class="preview-container">
      <h3 *ngIf="showDetails">
        {{value?.name}}
      </h3>
      <p-image
        [alt]="value?.name"
        [height]="height"
        [preview]="allowDetailView"
        [src]="value?.resource"
        [width]="width">
      </p-image>
      <div *ngIf="showDetails">
        created: {{value?.created}}
      </div>
      <div *ngIf="showDetails">
        createdBy: {{value?.createdBy}}
      </div>
    </div>

  </div>

  <button
    (click)="onClear()"
    *ngIf="showClearButton"
    [disabled]="disabled"
    class="p-button-secondary core-input-button-clear p-inputgroup-addon"
    icon="pi pi-times"
    pButton
    tabindex="-1">
  </button>

  <label *ngIf="floatLabel" [for]="id" class="core_input_float_label">{{floatLabel}}</label>
</div>
<div
  [ngClass]="[label ? 'core_input_error_container_with_label' : 'core_input_error_container_with_float_label', removeDefaultBottomErrorMargin ? '' : 'core_input_error_container_min_height']"
  class="core_input_error">
  <small *ngIf="control.touched && control.errors?.required">
    Dieses Feld ist erforderlich!
  </small>
</div>

