import {Injectable} from '@angular/core';
import {ConfirmationService} from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class ConfirmService {

  constructor(private confirmationService: ConfirmationService) {
  }

  /**
   * create a new confirm dialog which the user has to either accept or reject
   *
   * @param header name of the dialog
   * @param message description shown in the dialog
   * @param accept (optional) callback function which is triggered when the user accepts
   * @param reject callback function which is triggered when the user rejects
   * @param acceptLabel (optional) label for the accept button
   * @param rejectLabel (optional) label for the reject button
   * @param icon (optional) icon which is shown in the dialog on the left side of the message
   * @param acceptIcon (optional) is shown in the accept button on the left side of the acceptLabel
   * @param rejectIcon (optional) is shown in the reject button on the left side of the rejectLabel
   */
  confirm(header: string,
          message: string,
          accept?: Function,
          reject?: Function,
          acceptLabel: string = 'Ja',
          rejectLabel: string = 'Nein',
          icon: string = 'pi pi-exclamation-triangle',
          acceptIcon: string = 'pi pi-check',
          rejectIcon: string = 'pi pi-times') {
    this.confirmationService.confirm({
      key: 'confirmDialog',
      header: header,
      message: message,
      accept: () => {
        if (accept) {
          accept();
        }
      },
      reject: () => {
        if (reject) {
          reject();
        }
      },
      acceptLabel: acceptLabel,
      rejectLabel: rejectLabel,
      icon: icon,
      acceptIcon: acceptIcon,
      rejectIcon: rejectIcon,
      acceptButtonStyleClass: '',
      rejectButtonStyleClass: 'p-button-text'
    });
  }

}
