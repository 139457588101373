import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'core-button-submit',
  template: `
    <core-button
      [id]="id"
      [text]="text"
      [icon]="['fas', 'check']"
      [disabled]="disabled"
      [width]="width"
      (click)="onClick.emit($event)">
    </core-button>
  `
})
export class ButtonSubmitComponent {

  @Input() id: string;
  @Input() disabled: boolean;
  @Input() width: string;

  @Input() text: string = 'Änderungen speichern';

  @Output() onClick = new EventEmitter<any>();

}
