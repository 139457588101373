import {SortDirectionEnum} from './SortDirectionEnum';

export class Sort {

  constructor(public fieldName: string,
              public direction: SortDirectionEnum) {
  }

  // TODO refactor this in the backend
  public static mapList(list: Sort[]): string {
    let result = '';
    for (let i = 0; i < list.length; i++) {
      if (i > 0) {
        result = result + ',';
      }
      result = result + list[i].fieldName + ':' + list[i].direction;
    }
    return result;
  }

}
