import {Component, Input} from '@angular/core';
import {AppInfo} from '../../../../dtos/general/AppInfo';
import Constants from '../../../../ConstantsMenu';
import {PermissionTargetEnum} from '../../../../enums/PermissionTargetEnum';
import {AuthUtils} from '../../../../utils/AuthUtils';
import {AuthenticationService} from '../../../../services/rest/authentication.service';
import {Router} from '@angular/router';
import {AppRoutingModule} from '../../../../app-routing.module';
import {UserService} from '../../../../services/rest/user.service';
import {ConfirmService} from '../../../../services/confirm.service';
import {MessageService} from '../../../../services/message.service';
import {DataService} from '../../../../services/data.service';
import {OrganizationService} from '../../../../services/rest/organization.service';
import {LanguageEnum} from '../../../../enums/LanguageEnum';
import {CoreDialogService} from '../../dialog/core-dialog.service';
import {ChangeLanguageDialog} from '../../dialog/change-language/change-language.dialog';
import {Organization} from '../../../../dtos/organization/Organization';

@Component({
  selector: 'core-top-menu',
  templateUrl: 'top-menu.component.html',
  styleUrls: ['top-menu.component.scss']
})
export class TopMenuComponent {

  Constants = Constants;
  LanguageEnum = LanguageEnum;
  landingPage = AppRoutingModule.landingPageURL;

  // for now this is always true
  menuExpanded: boolean = true;

  @Input() appInfo: AppInfo;
  @Input() username: string;

  organization: Organization;

  constructor(private authenticationService: AuthenticationService,
              private userService: UserService,
              private organizationService: OrganizationService,
              public dataService: DataService,
              private confirmService: ConfirmService,
              private messageService: MessageService,
              private router: Router,
              private coreDialogService: CoreDialogService) {
  }

  isMenuVisible(permissions: string[]): boolean {
    for (const permission of permissions) {
      if (permission === PermissionTargetEnum.NO_PERMISSION) {
        return true;
      }
      if (AuthUtils.hasAccessTo(permission)) {
        return true;
      }
    }
    return false;
  }

  onExpandButtonClicked() {
    console.log('On menu expand button clicked - this feature is not enabled!');
    this.menuExpanded = !this.menuExpanded;
    // this.app.userService.saveSideMenuExpandOption(this.username, this.expandMenu);
  }

  onLogout() {
    this.authenticationService.logoutUser();
  }

  onChangePassword() {
    const userId: number = this.authenticationService.getUserIdFromToken();
    this.router.navigate(['users/change-password/' + userId]);
  }

  onResetPassword(): void {
    this.confirmService.confirm(`Passwort zurücksetzen`,
      `Wollen Sie das Passwort für Benutzer ${this.username} wirklich zurücksetzen? <br>` +
      `Es wird eine E-Mail mit einem neuen Passwort an ${this.username} gesendet! <br>` +
      `Dieser Vorgang kann nicht rückgängig gemacht werden.`,
      () => {
        this.userService.resetUserPassword(this.username).toPromise().then(() => {
          this.messageService.addSuccessMessage(`Passwort von ${this.username} wurde erfolgreich zurückgesetzt!`);
        }).catch(err => {
          console.log(err);
        });
      });
  }

  getAppInfoTooltip(): string {
    return '';
    if (!this.appInfo) {
      return null;
    }
    return 'instance: ' + this.appInfo.instance + '\n' +
      'build: ' + this.appInfo.build + '\n' +
      'buildOn: ' + this.appInfo.buildOn + '\n' +
      'db: ' + this.appInfo.databaseName;
  }

  onChangeLanguage() {
    this.coreDialogService.create(ChangeLanguageDialog, 'Change language');
  }

}
