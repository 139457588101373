import {NgModule} from '@angular/core';
import {CoreInputModule} from './input/core-input.module';
import {CoreMenuModule} from './menu/core-menu.module';
import {CoreButtonModule} from './button/core-button.module';
import {CoreDirectiveModule} from './directive/core-directive.module';
import {CoreDialogModule} from './dialog/core-dialog.module';
import {CoreDialogService} from './dialog/core-dialog.service';

@NgModule({
  declarations: [],
  imports: [
    CoreButtonModule,
    CoreInputModule,
    CoreMenuModule,
    CoreDirectiveModule,
    CoreDialogModule],
  exports: [
    CoreButtonModule,
    CoreInputModule,
    CoreMenuModule,
    CoreDirectiveModule,
    CoreDialogModule],
  providers: [CoreDialogService]
})
export class CoreModule {

}
