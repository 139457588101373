import {Component, OnInit} from '@angular/core';
import {UserService} from '../../../services/rest/user.service';
import {MessageService} from '../../../services/message.service';
import {ActivatedRoute} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthenticationService} from '../../../services/rest/authentication.service';
import {SelectItem} from '../../../dtos/SelectItem';
import {AuthUtils} from '../../../utils/AuthUtils';
import {PermissionTargetEnum} from '../../../enums/PermissionTargetEnum';
import {InputButtonIconType} from '../../core/button/button.utils';
import {ConfirmService} from '../../../services/confirm.service';
import {Organization} from '../../../dtos/organization/Organization';
import {OrganizationUpdate} from '../../../dtos/organization/OrganizationUpdate';
import {OrganizationService} from '../../../services/rest/organization.service';
import {DataService} from '../../../services/data.service';
import {TimezoneService} from '../../../services/timezone.service';
import {TranslationService} from '../../../services/rest/translation.service';

@Component({
  selector: 'app-organization-edit',
  templateUrl: 'organization-edit.component.html',
  styleUrls: ['organization-edit.component.scss']
})
export class OrganizationEditComponent implements OnInit {
  InputButtonIconType = InputButtonIconType;

  organization: Organization;

  form: FormGroup;

  languages: SelectItem[] = [];
  timezones: SelectItem[] = [];

  userCanEdit: boolean = AuthUtils.canEdit(PermissionTargetEnum.ORGANIZATION);

  constructor(private authService: AuthenticationService,
              private userService: UserService,
              private timeZoneService: TimezoneService,
              private translationService: TranslationService,
              private organizationService: OrganizationService,
              private dataService: DataService,
              private messageService: MessageService,
              private formBuilder: FormBuilder,
              private activatedRoute: ActivatedRoute,
              private confirmService: ConfirmService) {
  }

  ngOnInit() {
    this.getLanguages();
    this.getOrganization();
    this.getTimeZones();
  }

  submitChanges() {
    if (this.form.valid) {
      const organizationUpdate: OrganizationUpdate = {
        id: this.form.controls.id.value,
        name: this.form.controls.name.value,
        timezone: this.form.controls.timezone.value,
        languages: this.form.controls.languages.value.map((id: number) => this.languages.find(l => l.id === id).description),
        logoId: this.form.controls.logo.value?.id
      };
      // map language values
      this.organizationService.updateOrganization(organizationUpdate).subscribe(() => {
        this.messageService.addSuccessMessage('Organisation erfolgreich bearbeitet!');
      });
    }
  }

  onDeleteOrganization(organization: Organization): void {
    this.confirmService.confirm(
      `Organisation löschen`,
      `Wollen Sie die Organisation ${organization.name} wirklich löschen? <br>` +
      `Dieser Vorgang kann nicht rückgängig gemacht werden.`,
      () => {
      });
  }

  private getOrganization() {
    this.organizationService.getCurrentOrganization().subscribe((organization: Organization) => {
      this.organization = organization;
      this.createForm(organization);
    }, () => {
    });
  }

  private createForm(organization: Organization) {
    this.form = this.formBuilder.group({
      id: [organization.id],
      logo: [organization.logo, [Validators.required]],
      name: [organization.name, [Validators.required, Validators.minLength(3), Validators.maxLength(255)]],
      languages: [organization.languages.map(l => this.languages.find(lang => lang.description == l).id)],
      timezone: [organization.timezone, [Validators.required]]
    });
    // the user can not edit anything if there is no edit permission
    if (!this.userCanEdit) {
      this.form.disable();
    }
  }

  private getTimeZones() {
    this.timeZoneService.getTimezones().subscribe(timezones => {
      this.timezones = timezones.map(t => ({
        id: t.value,
        name: t.name[this.dataService.getCurrentLanguage()],
        description: null
      }));
    });
  }

  private getLanguages() {
    this.languages = this.translationService.getTranslations()
      .map((l, index) => ({
        id: index + 1,
        name: l.name,
        description: l.lang,
        iconClass: l.iconClass
      }));
  }

}
