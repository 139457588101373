import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Globals} from '../../global/globals';
import {Observable} from 'rxjs';
import {SelectItem} from '../../dtos/SelectItem';
import {Group} from '../../dtos/group/Group';
import {GroupCreate} from '../../dtos/group/GroupCreate';
import {GroupUpdate} from '../../dtos/group/GroupUpdate';
import {GroupFilter} from '../../dtos/group/GroupFilter';
import {PagedGroup} from '../../dtos/group/PagedGroup';
import {Sort} from '../../components/core/table/sort/Sort';

@Injectable({
  providedIn: 'root'
})
export class GroupService {

  pageMinDelay: number = this.globals.pageMinDelay;

  private groupBaseUrl: string = this.globals.backendUri + '/groups';
  private selectUrl: string = this.groupBaseUrl + '/select';

  constructor(private http: HttpClient, private globals: Globals) {
  }

  /**
   * get all groups as select-items
   */
  getAllGroupsAsSelectItems(): Observable<SelectItem[]> {
    console.log('Get all groups as select items');
    return this.http.get<SelectItem[]>(this.selectUrl);
  }

  /**
   * get all groups with the given filters
   *
   * @param groupFilter
   */
  getGroups(groupFilter: GroupFilter): Observable<PagedGroup> {
    console.log('getGroups', JSON.stringify(groupFilter));
    if (!groupFilter) {
      console.log('UserFilter must be defined');
      return new Observable<PagedGroup>();
    }
    if (groupFilter.page === null || groupFilter.page === undefined || groupFilter.page < 0) {
      console.log('Invalid page value ' + groupFilter.page);
      return new Observable<PagedGroup>();
    }
    if (!groupFilter.size || groupFilter.size < 1) {
      console.log('Invalid page size ' + groupFilter.size);
      return new Observable<PagedGroup>();
    }

    let params = new HttpParams();
    params = params.set('size', groupFilter.size.toString());
    params = params.set('page', groupFilter.page.toString());
    params = params.set('sortList', Sort.mapList(groupFilter.sortList));
    if (groupFilter.name) {
      params = params.set('name', groupFilter.name);
    }
    return this.http.get<PagedGroup>(this.groupBaseUrl, {params});
  }

  /**
   * get a group by its id
   *
   * @param groupId
   */
  getGroupById(groupId: number): Observable<Group> {
    console.log('Get group with id ' + groupId);
    return this.http.get<Group>(this.groupBaseUrl + '/' + groupId);
  }

  /**
   * create a new group
   *
   * @param group
   */
  createGroup(group: GroupCreate): Observable<Group> {
    console.log('Create group with name ' + group.name);
    return this.http.post<Group>(this.groupBaseUrl, group);
  }

  /**
   * update an existing group
   * update name, description and assigned permissions
   *
   * @param group
   */
  updateGroup(group: GroupUpdate): Observable<Group> {
    console.log('Update group with id ' + group.id);
    return this.http.put<Group>(this.groupBaseUrl, group);
  }

  /**
   * delete a group
   *
   * @param id
   */
  deleteGroupById(id: number) {
    console.log('Delete group with id ' + id);
    return this.http.delete(this.groupBaseUrl + '/' + id);
  }

}
