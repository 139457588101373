import {Injectable} from '@angular/core';
import {AppInfo} from '../../dtos/general/AppInfo';
import {Observable, of} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppInfoService {

  constructor() {
  }

  // for now this is some static data
  // this values should be set in the build process
  // maybe add a service in the backend if required?
  /**
   * get information about the app
   */
  getAppInfo(): Observable<AppInfo> {
    const appInfo: AppInfo = {
      build: 'SoMeHaSh',
      buildOn: '01.01.2022',
      databaseName: 'development@core',
      instance: 'development',
      version: '0.0.1'
    };
    return of(appInfo);
  }

}
