<div class="side-menu-container">

  <!-- Logo -->
  <div class="side-menu-logo-wrapper">
    <div class="side-menu-logo">

      <div *ngIf="false" class="side-menu-expand-button">
        <i (click)="onExpandButtonClicked()" class="pi pi-bars"></i>
      </div>

      <img
        *ngIf="menuExpanded"
        [routerLink]="landingPage"
        [src]="dataService?.getOrganization()?.logo?.resource ?
      dataService?.getOrganization()?.logo?.resource :
      './assets/pictures/logo.png'"
        alt="logo"
        class="side-menu-logo-image">
    </div>
  </div>
  <!-- Navigation -->
  <div class="side-menu-body">

    <ng-container *ngFor="let item of Constants.TOP_MENU">
      <div
        *ngIf="isMenuVisible(item.permissions)"
        [routerLink]="item.link"
        class="side-menu-body-menu-item side-menu-flex"
        routerLinkActive="side-menu-item-active">
        <fa-icon [fixedWidth]="true" [icon]="item.icon" class="side-menu-body-menu-item-icon"></fa-icon>
        <div *ngIf="menuExpanded" class="side-menu-body-menu-item-text">{{item.name | translate}}</div>
      </div>
    </ng-container>

  </div>

  <!-- Footer -->
  <div [ngClass]="!menuExpanded ? 'side-menu-footer-not-expanded' : ''" class="side-menu-footer">
    <div (click)="op.toggle($event)" class="side-menu-content" id="side-menu-footer">
      <div style="display: flex; justify-content: space-between; align-items: end; width: 200px;">
        <div style="width: 100%; margin-left: 10px;">
          <div
            class="side-menu-footer-firstname">
            {{ dataService.getLoggedInUser().firstName}}
          </div>
          <div
            class="side-menu-footer-lastname">
            {{ dataService.getLoggedInUser().lastName}}
          </div>
        </div>
        <img
          class="profile-picture"
          *ngIf="dataService.getLoggedInUser().image"
          [src]="dataService.getLoggedInUser().image?.resource"
          style="width: 50px; height: 50px; border-radius: 50%;">
        <fa-icon
          *ngIf="!dataService.getLoggedInUser().image"
          [icon]="['fas', 'user-circle']"
          [size]="'3x'">
        </fa-icon>

      </div>

      <p-overlayPanel #op>
        <div (click)="onLogout()" id="button-logout" style="cursor: pointer">
          <fa-icon [icon]="['fas', 'sign-out-alt']"></fa-icon>
          Logout
        </div>
        <div (click)="onChangePassword()" id="button-change-password" style="cursor: pointer">
          <fa-icon [icon]="['fas', 'key']"></fa-icon>
          Passwort ändern
        </div>
        <div (click)="onResetPassword()" id="button-reset-password" style="cursor: pointer">
          <fa-icon [icon]="['fas', 'key']"></fa-icon>
          Passwort zurücksetzten
        </div>
        <div (click)="onChangeLanguage()" id="button-reset-password2" style="cursor: pointer">
          <span [ngClass]="LanguageEnum.getIconClassByLang(dataService.getCurrentLanguage())"
                style="margin-left: -5px"></span> Sprache ändern
        </div>
      </p-overlayPanel>
      <div class="side-menu-footer-version-wrapper">
        <div [pTooltip]="getAppInfoTooltip()" class="side-menu-footer-version" tooltipPosition="top">
          <ng-container>v{{appInfo?.version ? appInfo.version : 'unknown'}}</ng-container>
        </div>
      </div>
    </div>
  </div>

</div>
