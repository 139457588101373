import {JwtHelperService} from '@auth0/angular-jwt';
import {SimplePermission} from '../dtos/permission/SimplePermission';
import {PermissionTargetEnum} from '../enums/PermissionTargetEnum';
import {PermissionActionEnum} from '../enums/PermissionActionEnum';

export class AuthUtils {

  static getLoggedInUserName(): string {
    const token = this.getToken();
    if (!token || token.length === 0) {
      return 'No user!';
    }
    const jwtService = new JwtHelperService();
    const decodedToken = jwtService.decodeToken(token);
    return decodedToken.sub;
  }

  static getToken() {
    const token = localStorage.getItem('token');
    return token ? token : '';
  }

  static getTokenExpirationDate(): Date {
    const jwtService = new JwtHelperService();
    return jwtService.getTokenExpirationDate(this.getToken());
  }

  static getUserRoleAndPermissions(): SimplePermission[] {
    const jwtService = new JwtHelperService();
    const decodedToken = jwtService.decodeToken(this.getToken());
    const permissions: SimplePermission[] = [];
    const tokenRoles = decodedToken?.rol;
    if (!tokenRoles) {
      return [];
    }
    const permissionTokenList = tokenRoles.split(',');
    for (const p of permissionTokenList) {
      const sep = p.indexOf(':');
      if (sep == null || sep <= 0) {
        continue;
      }
      const action: PermissionActionEnum = PermissionActionEnum.getEnumByValue(p.substr(0, sep));
      const target: PermissionTargetEnum = PermissionTargetEnum.getEnumByValue(p.substr(sep + 1, p.length));
      if (action && target) {
        permissions.push(new SimplePermission(action, target));
      }
    }
    return permissions;
  }

  static hasAccessTo(target: string): boolean {
    if (!target) {
      return false;
    }
    return this.getUserRoleAndPermissions().filter(p => p.target === target).length > 0;
  }

  // edit permission also includes read permission
  static canRead(permissionTypeEnum: PermissionTargetEnum): boolean {
    if (!permissionTypeEnum) {
      return false;
    }
    return this.getUserRoleAndPermissions().findIndex(p => p.target === permissionTypeEnum) !== -1;
  }

  static canEdit(permissionTypeEnum: PermissionTargetEnum): boolean {
    if (!permissionTypeEnum) {
      return false;
    }
    return this.getUserRoleAndPermissions().findIndex(p =>
      p.target === permissionTypeEnum &&
      p.action === PermissionActionEnum.EDIT) !== -1;
  }

}
