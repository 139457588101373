import {NgModule} from '@angular/core';
import {DoughnutBudgetIndicatorComponent} from './doughnut-budget-indicator/doughnut-budget-indicator.component';
import {ChartModule} from 'primeng/chart';
import {NgIf, NgStyle} from '@angular/common';

@NgModule({
  declarations: [DoughnutBudgetIndicatorComponent],
  imports: [
    ChartModule,
    NgIf,
    NgStyle
  ],
  exports: [DoughnutBudgetIndicatorComponent],
})
export class CoreChartModule {
}
