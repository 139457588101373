import {Component, EventEmitter, Injector, Input, OnInit, Output} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
  NgControl,
  ValidationErrors
} from '@angular/forms';
import {Translation} from '../../../../dtos/translation/Translation';

@Component({
  selector: 'core-input-translation-text',
  templateUrl: 'input-translation-text.component.html',
  styleUrls: ['input-translation-text.component.scss', '../core-input.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: InputTranslationTextComponent,
      multi: true
    }]
})
export class InputTranslationTextComponent implements ControlValueAccessor, OnInit {

  @Input() id: string;
  @Input() label: string;
  // TODO: the float label styling
  @Input() floatLabel: string;
  @Input() topLabel: string;
  @Input() placeholder: string = '';
  @Input() showClearButton: boolean = true;

  @Input() removeDefaultBottomErrorMargin: boolean = false;

  @Output() onKeyUpEnter = new EventEmitter<string>();

  // control of the form
  control: NgControl;

  // array which holds exactly the same value as the form but is used to display and edit it
  // this is needed to know which error is linked to which entry of the form
  translations: { key: string, value: string, errors: ValidationErrors | null }[] = [];

  // value of the form
  value: Translation;

  disabled = false;
  private onTouched: Function;
  private onChanged: Function;

  constructor(private inj: Injector) {
    if (!this.id) {
      this.id = 'core-input-translation-text-' + Math.random();
    }
  }

  ngOnInit() {
    this.control = this.inj.get(NgControl);
  }

  setTranslationArray() {
    const result: { key: string, value: string, errors: ValidationErrors | null }[] = [];
    for (const property in this.value) {
      result.push({key: property, value: this.value[property as keyof Translation], errors: null});
    }
    this.translations = result;
  }

  onClear(key: string) {
    this.translations.find(t => t.key === key).value = null;
    this.valueChanged(key, null);
    this.onKeyUpEnter.emit(null)
  }

  valueChanged(key: string, value: string) {
    this.onTouched();
    this.value[key as keyof Translation] = value;
    this.onChanged(this.value);
    this.validate(this.control.control);
  }

  writeValue(value: Translation): void {
    this.value = value;
    this.setTranslationArray();
    this.validate(this.control.control);
  }

  registerOnChange(fn: Function): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: Function): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  validate(control: AbstractControl<any, any>): ValidationErrors {
    if (!control) return null;
    if (Object.prototype.toString.call(control.value) === "[object String]") {
      return null; // this.validate((this.control.control as any));
    }
    // here the passed form control could already have updated values
    // however this is not required to check since the write value change will be called anyway
    // which will then triggers the validate function again anyway
    // so here it is fine to loop through the translations array
    // store all validation errors here
    let lastErrors = null;
    for (const property in control.value) {
      const errors = this.validateSingleValue(control, control.value[property]);
      // set the error in the translation value array
      this.translations.find(t => t.key === property).errors = errors;
      // when there is at least one error simply overwrite the result
      if (errors) lastErrors = errors;
    }
    // it is fine to return any error above
    // there are multiple inputs but only one of them can be returned anyway
    // set errors to form
    control.setErrors(lastErrors);
    // and return them
    return lastErrors;
  }

  validateSingleValue(control: AbstractControl<any, any>, val: string): ValidationErrors | null {
    // first get the validator function specified by the form (via form builder or maybe later added)
    // not sure why but on some occasions it is sufficient to use this.control,
    // however sometimes it is required to use this.control.control
    let validatorFn: (control: AbstractControl) => ValidationErrors | null = control?.validator;
    // return null if there is no validator in place
    if (!validatorFn) return null;
    // next create a dummy form control which will be validated by the function above
    const dummyFormControl: AbstractControl = new FormControl();
    // set value to be checked
    dummyFormControl.setValue(val);
    // run the check
    return validatorFn(dummyFormControl);
  }

}
