import {Component, OnInit} from '@angular/core';
import {PrimeNGConfig} from 'primeng/api';
import {AppInfo} from './dtos/general/AppInfo';
import {DataService} from './services/data.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'Frontend';

  appInfo: AppInfo = this.dataService.getAppInfo();

  constructor(private dataService: DataService,
              private primengConfig: PrimeNGConfig,
              private router: Router) {
  }

  ngOnInit() {
    this.primengConfig.ripple = true;
  }

  userLoggedIn(): boolean {
    return this.dataService.getLoggedInUser() != null;
  }

  getUserNameFromToken(): string {
    return this.dataService.getLoggedInUser()?.email;
  }

  showSideMenu(): boolean {
    const path = this.router.url;
    return path.startsWith('/administration');
  }

}
