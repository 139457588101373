import {NgModule} from '@angular/core';
import {FileTableComponent} from './table/file-table.component';
import {SharedModule} from '../../shared/shared.module';
import {FileCreateComponent} from './create/file-create.component';
import {FileEditComponent} from './edit/file-edit.component';

@NgModule({
  declarations: [
    FileCreateComponent,
    FileEditComponent,
    FileTableComponent
  ],
  imports: [
    SharedModule
  ],
  exports: [
    FileCreateComponent,
    FileEditComponent,
    FileTableComponent
  ],
  providers: []
})
export class FileModule {

}
