import {Component, OnInit} from '@angular/core';
import {UserService} from '../../../services/rest/user.service';
import {MessageService} from '../../../services/message.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthenticationService} from '../../../services/rest/authentication.service';
import {PermissionService} from '../../../services/rest/permission.service';
import {GroupService} from '../../../services/rest/group.service';
import {SelectItem} from '../../../dtos/SelectItem';
import {GroupCreate} from '../../../dtos/group/GroupCreate';

@Component({
  selector: 'app-group-create',
  templateUrl: './group-create.component.html',
  styleUrls: ['./group-create.component.scss']
})
export class GroupCreateComponent implements OnInit {

  form: FormGroup;

  allPermissions: SelectItem[] = [];

  constructor(private formBuilder: FormBuilder,
              private authService: AuthenticationService,
              private route: ActivatedRoute,
              private userService: UserService,
              private permissionService: PermissionService,
              private groupService: GroupService,
              private messageService: MessageService,
              private router: Router) {
  }

  ngOnInit() {
    this.createForm();
    this.getPermissions();
  }

  submitChanges() {
    if (this.form.valid) {
      const group: GroupCreate = {
        name: this.form.controls.name.value,
        description: this.form.controls.description.value,
        permissionIds: this.form.controls.permissions.value
      };
      this.groupService.createGroup(group).subscribe(() => {
        this.messageService.addSuccessMessage('Gruppe erfolgreich erstellt!');
        console.log('Redirect to /groups with params name=' + group.name);
        this.router.navigate(['/groups'], {queryParams: {name: group.name}});
      });
    }
  }

  private getPermissions() {
    this.permissionService.getAllPermissionsAsSelectItems().subscribe(permissions => this.allPermissions = permissions);
  }

  private createForm() {
    this.form = this.formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(255)]],
      description: ['', [Validators.maxLength(255)]],
      permissions: [[]]
    });
  }

}
