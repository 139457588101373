import {EnumUtils} from './utils/EnumUtils';

export enum PermissionActionEnum {
  READ = 'READ',
  EDIT = 'EDIT'
}

export namespace PermissionActionEnum {
  export function getAll(): PermissionActionEnum[] {
    return EnumUtils.getAll<PermissionActionEnum>(PermissionActionEnum, 'string');
  }

  export function getEnumByValue(value: string): PermissionActionEnum {
    return EnumUtils.getEnumByValue<PermissionActionEnum>(PermissionActionEnum, 'string', value);
  }
}
