import {NgModule} from '@angular/core';
import {AlphabeticalIndexPipe} from './alphabetical-index.pipe';
import {TranslationPipe} from './translation.pipe';

@NgModule({
  declarations: [AlphabeticalIndexPipe, TranslationPipe],
  imports: [],
  exports: [AlphabeticalIndexPipe, TranslationPipe]
})
export class PipesModule {

}
