<div class="row pt-5">

  <div class="col-md-6 mx-auto">

    <span class="anchor" id="formLogin"></span>

    <div class="card registration-card-body-border-radius">

      <div class="card-header">
        <h3 class="mb-0">{{'AUTHENTICATION.REGISTRATION.HEADER' | translate}}</h3>
      </div>

      <form [formGroup]="form" class="registration-card-body">

        <core-input-text
          [showClearButton]="false"
          [topLabel]="'AUTHENTICATION.REGISTRATION.FIRSTNAME' | translate"
          formControlName="firstName"
          id="firstName">
        </core-input-text>

        <core-input-text
          [showClearButton]="false"
          [topLabel]="'AUTHENTICATION.REGISTRATION.LASTNAME' | translate"
          formControlName="lastName"
          id="lastName">
        </core-input-text>

        <core-input-email
          [showClearButton]="false"
          [topLabel]="'AUTHENTICATION.REGISTRATION.EMAIL' | translate"
          formControlName="email"
          id="email">
        </core-input-email>

        <core-input-password
          [enableBrowserSuggestion]="true"
          [showClearButton]="false"
          [topLabel]="'AUTHENTICATION.REGISTRATION.PASSWORD' | translate"
          formControlName="password"
          id="password">
        </core-input-password>

        <core-input-password
          [enableBrowserSuggestion]="true"
          [showClearButton]="false"
          [topLabel]="'AUTHENTICATION.REGISTRATION.PASSWORD_CONFIRM' | translate"
          formControlName="confirmPassword"
          id="confirmPassword">
        </core-input-password>

        <core-button
          (click)="onRegister()"
          [disabled]="form.invalid"
          [icon]="['fas', 'check']"
          [text]="'AUTHENTICATION.REGISTRATION.REGISTER' | translate">
          [width]="'50%'"
          id="submit"
          style="text-align: center;">
        </core-button>

      </form>

      <p-progressBar *ngIf="loading" [style]="{'height': '10px'}" mode="indeterminate"></p-progressBar>

    </div>

  </div>

</div>
