import {EnumUtils} from './utils/EnumUtils';

export enum PermissionTargetEnum {
  FILES = 'FILES',
  GROUPS = 'GROUPS',
  ORGANIZATION = 'ORGANIZATION',
  PERMISSIONS = 'PERMISSIONS',
  USERS = 'USERS',
  USERS_PASSWORD = 'USERS_PASSWORD',

  NO_PERMISSION = 'NO_PERMISSION'
}

export namespace PermissionTargetEnum {
  export function getAll(): PermissionTargetEnum[] {
    return EnumUtils.getAll<PermissionTargetEnum>(PermissionTargetEnum, 'string');
  }

  export function getEnumByValue(value: string): PermissionTargetEnum {
    return EnumUtils.getEnumByValue<PermissionTargetEnum>(PermissionTargetEnum, 'string', value);
  }
}
