<div class="dashboard-grid">

  <div class="dashboard-header">
    <div>20 Arbeitstage im Rückblick</div>
  </div>

  <div class="dashboard-header">
    <div>Planung, Entwicklung, Trendanalyse</div>
  </div>

  <div class="dashboard-card dashboard-project-card">
    <div class="dashboard-project-card-header color-green">123</div>
    <div>abgeschlossene Projekte</div>
    <hr>
    <div class="dashboard-project-card-header color-red">12</div>
    <div>überfällige Projekte</div>
  </div>

  <div class="dashboard-card">
    <div style="display: flex; flex-direction: row; gap: 8rem;">
      <div style="display: flex; flex-direction: column; justify-content: space-evenly">
        <h4>Auslastung</h4>
        <p-dropdown
          (onChange)="updateChart(selectedTimeUnit)"
          [(ngModel)]="selectedTimeUnit"
          [options]="timeUnits"
          [style]="{width: '180px'}"
          placeholder="Zeitraum wählen">
        </p-dropdown>
        <p-dropdown
          (onChange)="updateChart(selectedTimeUnit)"
          [(ngModel)]="selectedLocation"
          [options]="locations"
          [style]="{width: '180px'}"
          placeholder="Alle Standorte">
        </p-dropdown>
      </div>
      <div style="display: flex; flex-direction: row; gap: 3rem">
        <div class="dashboard-inner-card">
          <core-doughnut-budget-indicator
            [color]="colorGraz"
            [percentage]="percentageGraz"
            [showText]="false"
            [size]="120"></core-doughnut-budget-indicator>
          <div>Graz</div>
        </div>
        <div class="dashboard-inner-card">
          <core-doughnut-budget-indicator
            [color]="colorWien"
            [percentage]="percentageWien"
            [showText]="false"
            [size]="120"></core-doughnut-budget-indicator>
          <div>Wien</div>
        </div>
        <div class="dashboard-inner-card">
          <core-doughnut-budget-indicator
            [color]="colorLinz"
            [percentage]="percentageLinz"
            [showText]="false"
            [size]="120"></core-doughnut-budget-indicator>
          <div>Linz</div>
        </div>
      </div>
    </div>
  </div>

  <div class="dashboard-card dashboard-project-card">
    <div class="dashboard-project-card-header color-green">{{ 123 | currency:'EUR':'€':'1.2-2':'de-DE' }}</div>
    <div>offene Forderungen</div>
    <hr>
    <div class="dashboard-project-card-header color-red">{{ -12 | currency:'EUR':'€':'1.2-2':'de-DE' }}</div>
    <div>offene Verbindlichkeiten</div>
  </div>

  <div #chartWrapper class="dashboard-card" style="display: flex; flex-direction: row;">
    <p-chart
      *ngIf="chartWrappe?.nativeElement?.offsetWidth > 0"
      [data]="auslastungData"
      [height]="(chartWrappe?.nativeElement?.offsetHeight - 10) + ''"
      [options]="options"
      [width]="(chartWrappe?.nativeElement?.offsetWidth - 30) + ''"
      type="bar">
    </p-chart>
  </div>

  <div class="dashboard-card dashboard-hours-chart">
    <h4>Leistungsbezogene Stunden</h4>
    <div>
      <p-chart [data]="data" [height]="'250px'" [options]="op" [width]="'250px'" type="doughnut"></p-chart>
    </div>
  </div>

  <div class="dashboard-card">
    <div style="display: flex; flex-direction: column;">
      <div class="grid-table">

        <div></div>
        <div class="table-label" style="grid-area: 2 / 1 / 6 / 2;">bestätigt</div>
        <div class="table-label" style="grid-area: 6 / 1 / 10 / 2;">offen</div>
        <div class="table-label" style="grid-area: 10 / 1 / 12 / 2;"></div>

        <!-- Property names as row headers on the left -->
        <div class="grid-header table-total-row"></div>
        <div class="grid-header table-row">Einnahmen</div>
        <div class="grid-header table-row">Materialaufwand</div>
        <div class="grid-header table-row">Personalaufwand</div>
        <div class="grid-header table-total-row">Gewinn</div>
        <div class="grid-header table-row">Einnahmen</div>
        <div class="grid-header table-row">Materialaufwand</div>
        <div class="grid-header table-row">Personalaufwand</div>
        <div class="grid-header table-total-row">Gewinn</div>
        <div class="grid-header table-row">Gewinnprognose</div>
        <div class="grid-header table-total-row">Differenz</div>

        <!-- Forecasts data, repeated for each week -->
        <ng-container *ngFor="let forecast of forecasts">
          <div
            [ngClass]="{'grid-last-cell' : lastInList(forecasts, forecast)}"
            class="table-cell table-total-row align-center">
            {{forecast.name}}
          </div>
          <div
            [ngClass]="{'grid-last-cell' : lastInList(forecasts, forecast)}"
            class="table-cell table-row">
            {{forecast.income | currency:'EUR'}}
          </div>
          <div
            [ngClass]="{'grid-last-cell' : lastInList(forecasts, forecast)}"
            class="table-cell table-row">
            {{forecast.expensesMaterial | currency:'EUR'}}
          </div>
          <div
            [ngClass]="{'grid-last-cell' : lastInList(forecasts, forecast)}"
            class="table-cell table-row">
            {{forecast.expensesPersonal | currency:'EUR'}}</div>
          <div
            [ngClass]="{'grid-last-cell' : lastInList(forecasts, forecast),
          'color-red': forecast.total < 0, 'color-green': forecast.total >= 0}"
            class="table-cell table-total-row">
            {{forecast.total | currency:'EUR'}}
          </div>
          <div
            [ngClass]="{'grid-last-cell' : lastInList(forecasts, forecast)}"
            class="table-cell table-row">
            {{forecast.plannedIncome | currency:'EUR'}}
          </div>
          <div
            [ngClass]="{'grid-last-cell' : lastInList(forecasts, forecast)}"
            class="table-cell table-row">
            {{forecast.plannedMaterialExpenses | currency:'EUR'}}
          </div>
          <div
            [ngClass]="{'grid-last-cell' : lastInList(forecasts, forecast)}"
            class="table-cell table-row">
            {{forecast.plannedPersonalExpenses | currency:'EUR'}}
          </div>
          <div
            [ngClass]="{'grid-last-cell' : lastInList(forecasts, forecast),
            'color-red': forecast.plannedTotal < 0, 'color-green': forecast.plannedTotal >= 0}"
            class="table-cell table-total-row">
            {{forecast.plannedTotal | currency:'EUR'}}
          </div>
          <div
            [ngClass]="{'grid-last-cell' : lastInList(forecasts, forecast)}"
            class="table-cell">
            {{forecast.forecast | currency:'EUR'}}
          </div>
          <div
            [ngClass]="{'grid-last-cell' : lastInList(forecasts, forecast),
            'color-red': forecast.forecastDifference < 0, 'color-green': forecast.forecastDifference >= 0}"
            class="table-cell table-total-row">
            <ng-container *ngIf="forecast.forecastDifference > 0">
              <fa-icon [icon]="['fas', 'arrow-up']"></fa-icon>
            </ng-container>
            <ng-container *ngIf="forecast.forecastDifference < 0">
              <fa-icon [icon]="['fas', 'arrow-down']"></fa-icon>
            </ng-container>
            {{forecast.forecastDifference | currency:'EUR'}}
          </div>
        </ng-container>
      </div>


    </div>
  </div>


</div>
