import {NgModule} from '@angular/core';
import {UserTableComponent} from './table/user-table.component';
import {UserCreateComponent} from './create/user-create.component';
import {UserEditComponent} from './edit/user-edit.component';
import {SharedModule} from '../../shared/shared.module';
import {UserChangePasswordComponent} from './change-password/user-change-password.component';

@NgModule({
  declarations: [
    UserTableComponent,
    UserCreateComponent,
    UserEditComponent,
    UserChangePasswordComponent
  ],
  imports: [
    SharedModule
  ],
  exports: [
    UserTableComponent,
    UserCreateComponent,
    UserEditComponent,
    UserChangePasswordComponent
  ],
  providers: []
})
export class UserModule {

}
