import {NgModule} from '@angular/core';
import {PermissionTableComponent} from './table/permission-table.component';
import {SharedModule} from '../../shared/shared.module';
import {PermissionEditComponent} from './edit/permission-edit.component';

@NgModule({
  declarations: [
    PermissionTableComponent,
    PermissionEditComponent
  ],
  imports: [
    SharedModule
  ],
  exports: [
    PermissionTableComponent,
    PermissionEditComponent
  ]
})
export class PermissionModule {

}
