import {NgModule} from '@angular/core';
import {ProjectsComponent} from './projects.component';
import {SharedModule} from '../../shared/shared.module';
import {ProjectCardComponent} from './card/project-card.component';
import {CurrencyPipe} from '@angular/common';
import {ChipModule} from 'primeng/chip';
import {CoreChartModule} from '../core/charts/core-chart.module';

@NgModule({
  declarations: [ProjectsComponent, ProjectCardComponent],
  imports: [SharedModule, ChipModule, CoreChartModule],
  exports: [ProjectsComponent, ProjectCardComponent],
  providers: [CurrencyPipe]
})
export class ProjectsModule {

}
