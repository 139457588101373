import {NgModule} from '@angular/core';
import {InputNumberModule} from 'primeng/inputnumber';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {MultiSelectModule} from 'primeng/multiselect';
import {TooltipModule} from 'primeng/tooltip';
import {DropdownModule} from 'primeng/dropdown';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {InputTextComponent} from './input-text/input-text.component';
import {InputTextModule} from 'primeng/inputtext';
import {ButtonModule} from 'primeng/button';
import {InputEmailComponent} from './input-email/input-email.component';
import {InputDropdownComponent} from './input-dropdown/input-dropdown.component';
import {InputMultiselectComponent} from './input-multiselect/input-multiselect.component';
import {ChipModule} from 'primeng/chip';
import {InputNumberComponent} from './input-number/input-number.component';
import {InputMoneyComponent} from './input-money/input-money.component';
import {InputPasswordComponent} from './input-password/input-password.component';
import {InputFileUploadComponent} from './input-file-upload/input-file-upload.component';
import {FileUploadModule} from 'primeng/fileupload';
import {CoreDirectiveModule} from '../directive/core-directive.module';
import {InputFileViewerComponent} from './input-file-viewer/input-file-viewer.component';
import {ImageModule} from 'primeng/image';
import {InputTranslationTextComponent} from './input-translation-text/input-translation-text.component';
import {InputTextAreaComponent} from './input-text-area/input-text-area.component';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {InputTranslationTextAreaComponent} from './input-translation-text-area/input-translation-text-area.component';

@NgModule({
  declarations: [
    InputMoneyComponent,
    InputNumberComponent,
    InputTextComponent,
    InputEmailComponent,
    InputDropdownComponent,
    InputMultiselectComponent,
    InputPasswordComponent,
    InputFileUploadComponent,
    InputFileViewerComponent,
    InputTranslationTextComponent,
    InputTextAreaComponent,
    InputTranslationTextAreaComponent
  ],
  imports: [
    InputNumberModule,
    DropdownModule,
    MultiSelectModule,
    ReactiveFormsModule,
    CommonModule,
    FormsModule,
    TooltipModule,
    FontAwesomeModule,
    InputTextModule,
    ButtonModule,
    ChipModule,
    FileUploadModule,
    CoreDirectiveModule,
    ImageModule,
    InputTextareaModule
  ],
  exports: [
    InputMoneyComponent,
    InputNumberComponent,
    InputTextComponent,
    InputEmailComponent,
    InputDropdownComponent,
    InputMultiselectComponent,
    InputPasswordComponent,
    InputFileUploadComponent,
    InputFileViewerComponent,
    InputTranslationTextComponent,
    InputTextAreaComponent,
    InputTranslationTextAreaComponent
  ]
})
export class CoreInputModule {

}
