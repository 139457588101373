import {Component} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {AppRoutingModule} from '../../../app-routing.module';
import {InputPasswordComponent} from '../../core/input/input-password/input-password.component';
import {Registration} from '../../../dtos/authentication/Registration';
import {UserService} from '../../../services/rest/user.service';
import {DataService} from '../../../services/data.service';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent {

  form: FormGroup;
  // After first submission attempt, form validation will start
  submitted = false;
  loading = false;

  redirectURL = AppRoutingModule.landingPageURL;

  constructor(private formBuilder: FormBuilder,
              private userService: UserService,
              private dataService: DataService,
              private router: Router) {
    this.form = this.formBuilder.group({
      firstName: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(255)]],
      lastName: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(255)]],
      email: ['', [Validators.required, Validators.email, Validators.maxLength(255)]],
      password: ['', [
        Validators.required,
        InputPasswordComponent.minOneLowerCaseCharacter(),
        InputPasswordComponent.minOneUpperCaseCharacter(),
        InputPasswordComponent.minOneNumberCaseCharacter(),
        Validators.minLength(8),
        Validators.maxLength(255)]],
      confirmPassword: ['', [InputPasswordComponent.matchValues('password'), Validators.required]]
    });
  }

  /**
   * Form validation will start after the method is called, additionally an AuthRequest will be sent
   */
  onRegister() {
    this.submitted = true;
    if (this.form.valid) {
      const registration: Registration = new Registration(
        this.form.controls.firstName.value,
        this.form.controls.lastName.value,
        this.form.controls.email.value,
        this.form.controls.password.value);
      this.registerUser(registration);
    } else {
      console.log('Invalid input');
    }
  }

  /**
   * Send authentication data to the authService. If the authentication was successfully, the user will be forwarded to the message page
   *
   * @param registration authentication data from the user registration form
   */
  registerUser(registration: Registration) {
    this.loading = true;
    this.userService.registerUser(registration).subscribe(
      () => {
        console.log('Successfully registered user: ' + registration.email);
        this.dataService.init();
        this.loading = false;
        console.log('Redirect to ' + this.redirectURL);
        this.router.navigate([this.redirectURL]);
      },
      error => {
        console.log('Could not log in due to:');
        console.log(error);
        this.loading = false;
      }
    );
  }

}
