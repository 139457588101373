import {Injectable, Injector} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Globals} from '../../global/globals';
import {Observable} from 'rxjs';
import {Organization} from '../../dtos/organization/Organization';
import {OrganizationUpdate} from '../../dtos/organization/OrganizationUpdate';
import {DataService} from '../data.service';
import {tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {

  pageMinDelay: number = this.globals.pageMinDelay;

  private organizationBaseUrl: string = this.globals.backendUri + '/organizations';

  private dataService: DataService;

  constructor(private http: HttpClient,
              private globals: Globals,
              injector: Injector) {
    setTimeout(() => this.dataService = injector.get(DataService));
  }

  /**
   * get the organization of the application
   */
  getCurrentOrganization(): Observable<Organization> {
    console.log('Get the organization of the application');
    return this.http.get<Organization>(this.organizationBaseUrl);
  }

  /**
   * update an existing organization
   * update name
   *
   * @param organization
   */
  updateOrganization(organization: OrganizationUpdate): Observable<Organization> {
    console.log('Update organization with id ' + organization.id);
    return this.http.put<Organization>(this.organizationBaseUrl, organization)
      .pipe(tap(() => {
        // reload list of all organizations in the data service
        this.dataService.fetchOrganization();
      }));
  }

}
