<core-input-dropdown
  [(ngModel)]="selection"
  [options]="options"
  [showClearButton]="false"
  [showIconClass]="true"
  id="selectLanguage"
  label="Language">
</core-input-dropdown>

<div class="p-dialog-footer remove-margin align-right">
  <core-button-slight
    (onClick)="closeDialog()"
    [icon]="['fas', 'times']"
    [showBorder]="false"
    [text]="'Abbrechen'"
    [type]="InputButtonIconType.PRIMARY">
  </core-button-slight>
  <core-button
    (onClick)="onSubmit()"
    [icon]="['fas', 'check']"
    [text]="'Bestätigen'">
  </core-button>
</div>

