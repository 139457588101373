import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-file-create',
  templateUrl: './file-create.component.html',
  styleUrls: ['./file-create.component.scss']
})
export class FileCreateComponent implements OnInit {

  form: FormGroup;

  constructor(private formBuilder: FormBuilder,
              private router: Router) {
  }

  ngOnInit() {
    this.createForm();
  }

  private createForm() {
    this.form = this.formBuilder.group({
      file: [],
      name: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(255)]],
      description: ['', [Validators.maxLength(255)]],
    });
    this.form.get('name').disable();
    this.form.get('description').disable();
    this.form.updateValueAndValidity();

    this.form.get('file').valueChanges.subscribe(val => {
      if (val != null) {
        console.log('New file uploaded with name=' + val?.name);
        console.log('Redirect to /files/:id with id=' + val?.id);
        this.router.navigate(['/files/' + val?.id]);
      }
    });
  }

}
