import {Component, Injector, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl} from '@angular/forms';
import {File} from '../../../../dtos/file/File';

@Component({
  selector: 'core-input-file-viewer',
  templateUrl: 'input-file-viewer.component.html',
  styleUrls: ['input-file-viewer.component.scss', '../core-input.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: InputFileViewerComponent,
    multi: true
  }]
})
export class InputFileViewerComponent implements ControlValueAccessor, OnInit {

  @Input() id: string;
  @Input() label: string;
  @Input() floatLabel: string;
  @Input() topLabel: string;
  @Input() removeDefaultBottomErrorMargin: boolean;
  @Input() showBorder: boolean = true;
  @Input() showClearButton: boolean = true;
  @Input() showDetails: boolean = true;

  // either define a height or a width, not booth!
  @Input() height: string = null;
  @Input() width: string = null;

  @Input() allowDetailView: boolean = true;

  control: NgControl;
  value: File;
  disabled = false;
  private onTouched: Function;
  private onChanged: Function;

  constructor(private inj: Injector) {
    if (!this.id) {
      this.id = 'core-input-file-viewer-' + Math.random();
    }
  }

  ngOnInit() {
    this.control = this.inj.get(NgControl);
  }

  onClear() {
    this.valueChanged(null);
  }

  valueChanged(value: File) {
    this.onTouched();
    this.value = value;
    this.onChanged(value);
  }

  writeValue(value: File): void {
    this.value = value;
  }

  registerOnChange(fn: Function): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: Function): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

}
