/* eslint-disable */
import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'alphabeticalIndex'
})
export class AlphabeticalIndexPipe implements PipeTransform {

  alphabet =
    ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

  // pipe works if input > 0 and input < length + length * length + length * length * length
  transform(input: number): string {

    // length = number of possible characters
    const length = this.alphabet.length;

    // input 1 => first character
    if (--input < 0) {
      return 'Index less than 1!';
    }
    // max index = length + length^2 + length^3
    if (input >= length + length * length + length * length * length) {
      return 'Index too high!';
    }

    if (input / length < 1) {
      return this.alphabet[input];
    }

    if (input < (length + length * length)) {
      const start = input - length;
      const currentStepFirstCharacter = Math.floor(start / length);
      const currentStepSecondCharacter = start - currentStepFirstCharacter * length;
      return this.alphabet[currentStepFirstCharacter] +
        this.alphabet[currentStepSecondCharacter];
    }

    const start = input - (length + length * length);
    const currentStepFirstCharacter = Math.floor(start / (length * length));
    const currentStepSecondCharacter = Math.floor((start - currentStepFirstCharacter * length * length) / length);
    const currentStepThirdCharacter = start - currentStepFirstCharacter * length * length - currentStepSecondCharacter * length;
    return this.alphabet[currentStepFirstCharacter] +
      this.alphabet[currentStepSecondCharacter] +
      this.alphabet[currentStepThirdCharacter];
  }

}
