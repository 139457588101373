import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Globals} from '../../global/globals';
import {SelectItem} from '../../dtos/SelectItem';
import {SelectItemMultiLanguage} from '../../dtos/SelectItemMultiLanguage';
import {DataService} from '../data.service';
import {Translation} from '../../dtos/translation/Translation';
import {LanguageEnum} from '../../enums/LanguageEnum';
import {TranslationPipe} from '../../pipes/translation.pipe';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {

  private translationPipe: TranslationPipe;

  private translations: { [lang: string]: any } = {};

  constructor(private http: HttpClient,
              private globals: Globals,
              private dataService: DataService) {
    this.translationPipe = new TranslationPipe(this);
  }

  public init(): Promise<any[]> {
    const promises = [
      this.loadTranslations('de')
    ];
    return Promise.all(promises);
  }

  public translate(value: string | Translation): string {
    let result = '';

    if (typeof value === 'string') {
      // Nested key support (e.g., 'CAR.NAME')
      const keys = value.split('.');
      let translationObj = this.translations[this.getCurrentLanguage()];
      for (let key of keys) {
        if (!translationObj[key]) {
          return '${translation}';  // Return fallback if translation is not found
        }
        translationObj = translationObj[key];
      }
      result = translationObj;

    } else if (typeof value === 'object') {
      result = (value as any)[this.getCurrentLanguage()];
    }

    return result && result.length > 0 ? result : '${translation}';
  }

  public getCurrentLanguage(): string {
    return this.dataService.getCurrentLanguage();
  }

  public mapSelectItemMultiLanguageToSelectItemDefaultLanguage(list: SelectItemMultiLanguage[]): SelectItem[] {
    return this.mapSelectItemMultiLanguageToSelectItem(this.dataService.getCurrentLanguage(), list);
  }

  public mapSelectItemMultiLanguageToSelectItem(language: string, list: SelectItemMultiLanguage[]): SelectItem[] {
    return list.map(e => {
      // @ts-ignore
      return {id: e.id, name: e.name[language], description: e.description[language]}
    });
  }

  getTranslations(): { lang: string, name: string, iconClass: string }[] {
    const languages = LanguageEnum.getAllDetailed();
    return languages.map(l => {
      return ({lang: l.lang, iconClass: l.iconClass, name: this.translationPipe.transform(l.name)});
    });
  }

  private loadTranslations(lang: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(`assets/i18n/${lang}.json`).subscribe(
        data => {
          this.translations[lang] = data;
          resolve(data);
        },
        error => {
          reject(error);
        }
      );
    });
  }

}
