import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {MessageService} from '../services/message.service';
import {AuthenticationService} from '../services/rest/authentication.service';
import {AppRoutingModule} from '../app-routing.module';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationTokenGuard implements CanActivate {

  constructor(private authenticationService: AuthenticationService,
              private router: Router,
              private messageService: MessageService) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.authenticationService.isLoggedIn()) {
      return true;
    } else {
      // If a redirectURL is given and it is not the dashboard url, the user wants to access a restricted area
      if (state.url && state.url !== AppRoutingModule.landingPageURL) {
        this.messageService.addWarnMessage('Um diesen Bereich zu öffnen ist ein Login erforderlich!');
      }
      console.log('Redirect to /login with query params redirectURL=' + state.url);
      this.router.navigate(['/login'], {queryParams: {redirectURL: state.url}});
      this.authenticationService.logoutUser();
      return false;
    }
  }

}
