import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {SelectItem} from 'primeng/api';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'dashboard.component.html',
  styleUrls: ['dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  auslastungData: any;
  selectedTimeUnit: string = '6weeks';
  selectedLocation: string = 'all';
  timeUnits: SelectItem[];
  locations: SelectItem[] = [
    {label: 'Alle Standorte', value: 'all', disabled: false},
    {label: 'Graz', value: 'Graz', disabled: false},
    {label: 'Wien', value: 'Wien', disabled: false},
    {label: 'Linz', value: 'Linz', disabled: false},
  ]

  @ViewChild('chartWrapper') chartWrappe: ElementRef;


  colorGraz = '#227c9d';
  colorWien = '#ffcb77';
  colorLinz = '#fe6d73';

  percentageGraz = 80;
  percentageWien = 92;
  percentageLinz = 77;
  forecasts: any[] = [
    {
      name: 'KW' + (this.getCurrentWeekNumber()),
      income: 120000,
      expensesMaterial: 40000,
      expensesPersonal: 45000,
      total: 35000,
      plannedIncome: 12000,
      plannedMaterialExpenses: 2000,
      plannedPersonalExpenses: 6000,
      plannedTotal: 4000,
      forecast: 25000,
      forecastDifference: 10000, // already correct as per your formula
    },
    {
      name: 'KW' + (this.getCurrentWeekNumber() + 1),
      income: 130000,
      expensesMaterial: 60000,
      expensesPersonal: 30000,
      total: 40000, // 130000 - 60000 - 30000
      plannedIncome: 10000,
      plannedMaterialExpenses: 2500,
      plannedPersonalExpenses: 3500,
      plannedTotal: 4000, // 10000 - 2500 - 3500
      forecast: 25000, // realistic based on income
      forecastDifference: 15000, // total - forecast
    },
    {
      name: 'KW' + (this.getCurrentWeekNumber() + 2),
      income: 90000,
      expensesMaterial: 20000,
      expensesPersonal: 12000,
      total: 58000, // 90000 - 20000 - 12000
      plannedIncome: 40000,
      plannedMaterialExpenses: 18000,
      plannedPersonalExpenses: 20000,
      plannedTotal: 2000, // 40000 - 18000 - 20000
      forecast: 30000, // closer to actual income
      forecastDifference: 28000, // total - forecast
    },
    {
      name: 'KW' + (this.getCurrentWeekNumber() + 3),
      income: 50000,
      expensesMaterial: 15000,
      expensesPersonal: 10000,
      total: 24000, // 50000 - 15000 - 10000
      plannedIncome: 60000,
      plannedMaterialExpenses: 17000,
      plannedPersonalExpenses: 20000,
      plannedTotal: 23000, // 60000 - 17000 - 20000
      forecast: 35000, // realistic based on income
      forecastDifference: -11000, // total - forecast
    },
    {
      name: 'KW' + (this.getCurrentWeekNumber() + 4),
      income: 65000,
      expensesMaterial: 22000,
      expensesPersonal: 17000,
      total: 26000, // 65000 - 22000 - 17000
      plannedIncome: 40000,
      plannedMaterialExpenses: 24000,
      plannedPersonalExpenses: 25000,
      plannedTotal: -9000, // 40000 - 24000 - 25000
      forecast: 40000, // realistic based on income
      forecastDifference: -14000, // total - forecast
    },
    {
      name: 'KW' + (this.getCurrentWeekNumber() + 5),
      income: 75000,
      expensesMaterial: 28000,
      expensesPersonal: 21000,
      total: 26000, // 35000 - 28000 - 21000
      plannedIncome: 85000,
      plannedMaterialExpenses: 30000,
      plannedPersonalExpenses: 30000,
      plannedTotal: 25000, // 85000 - 30000 - 30000
      forecast: 20000, // closer to actual income
      forecastDifference: 6000, // total - forecast
    }
  ];


  options = {
    scales: {
      y: {
        min: 0,
        max: 100,
        beginAtZero: true,  // Ensures that the y-axis starts at 0
        ticks: {
          stepSize: 10  // Adjust this value as needed for finer control over the y-axis ticks
        }
      }
    },
    plugins: {
      legend: {
        display: true,
        position: 'left',
        labels: {
          boxWidth: 50, // Adjusts the size of the color box, which impacts the width
          padding: 20,
        }
      },
      tooltip: {
        enabled: true
      }
    },
    maintainAspectRatio: false
  };

  data = {
    labels: ['Produktiv', 'Intern', 'Urlaub', 'Krank'],
    datasets: [
      {
        data: [65, 20, 12, 3],
        backgroundColor: [
          '#117F51',
          '#e76f51',
          '#e9c46a',
          '#264653',
        ],
        hoverBackgroundColor: [
          '#117F51',
          '#e76f51',
          '#e9c46a',
          '#264653',
        ]
      }
    ]
  };

  op = {
    indexAxis: 'y',
    plugins: {
      legend: {
        display: true,
        position: 'bottom', // Positioning the legend on the right
        align: "start",
        labels: {
          generateLabels: (chart: { data: any; }) => {
            const data = chart.data;
            return data.labels.map((label: any, index: string | number) => {
              const value = data.datasets[0].data[index];
              return {
                text: `${value}% ${label}`, // Formats the label with the percentage
                fillStyle: data.datasets[0].backgroundColor[index],
              };
            });
          },
          font: {
            size: 13,
            family: 'Nunito, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'
          }
        }
      }
    }
  };
  change = false;

  ngOnInit() {
    this.timeUnits = [
      {label: 'Nächsten 2 Wochen', value: '2weeks'},
      {label: 'Nächsten 6 Wochen', value: '6weeks'},
      {label: 'Nächste 5 Monate', value: '5months'}
    ];
    this.selectedTimeUnit = '6weeks'; // default selection
    this.updateChart(this.selectedTimeUnit);
  }

  updateChart(timeUnit: string) {
    switch (timeUnit) {
      case '2weeks':
      case '6weeks':
      case '5months':
        this.setChartData(6); // 150 days (5 months assuming 30 days per month)
        break;
    }
  }

  getCurrentWeekNumber(): number {
    const currentDate = new Date();
    const startDate = new Date(currentDate.getFullYear(), 0, 1); // Jan 1st of current year
    const days = Math.floor((currentDate.valueOf() - startDate.valueOf()) / (24 * 60 * 60 * 1000));
    return Math.ceil((currentDate.getDay() + 1 + days) / 7);
  }

  setChartData(days: number) {
    let labels = [];
    let GrazData = [];
    let WienData = [];
    let LinzData = [];

    for (let i = 0; i < days; i++) {
      if (i == 0)
        labels.push(`Diese Woche`);
      else if (i == 1)
        labels.push(`Nächste Woche`);
      else
        labels.push(` KW${this.getCurrentWeekNumber() + i}`);
      GrazData.push(this.randomPercentage(i, days));
      WienData.push(this.randomPercentage(i, days));
      LinzData.push(this.randomPercentage(i, days));
    }

    this.auslastungData = {
      labels: labels,
      datasets: [
        {
          label: 'Graz',
          backgroundColor: this.colorGraz, // Teal
          borderColor: this.colorGraz, // Teal
          data: GrazData,
          tension: 0.4

        },
        {
          label: 'Wien',
          backgroundColor: this.colorWien, // Coral
          borderColor: this.colorWien, // Coral
          data: WienData,
          tension: 0.4
        },
        {
          label: 'Linz',
          backgroundColor: this.colorLinz, // Amethyst
          borderColor: this.colorLinz, // Amethyst
          data: LinzData,
          tension: 0.4
        }
      ]
    };
    this.change = !this.change;
    if (!this.change) {
      this.percentageGraz = 81;
      this.percentageWien = 92;
      this.percentageLinz = 77;
    } else {
      this.percentageGraz = 80;
      this.percentageWien = 91;
      this.percentageLinz = 76;

    }
  }

  randomPercentage(index: number, total: number) {
    // `total` is the total number of days or time periods you are generating data for
    // `index` should start from 0 to total-1

    // Calculate a factor that linearly decreases from 1 to 0.5 based on the index
    let scale = 1 - (index / (2 * total));

    // Generate a percentage from 70 to 100, biased by the scaling factor
    let min = 70;
    let max = 100;
    return Math.floor((min + Math.random() * (max - min)) * scale + (max - min) * (1 - scale));
  }

  lastInList(list: any[], item: any) {
    return list && list.findIndex(i => i === item) === list.length - 1;
  }

}
