import {Component, OnInit} from '@angular/core';
import {MessageService} from '../../../services/message.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthenticationService} from '../../../services/rest/authentication.service';
import {AuthUtils} from '../../../utils/AuthUtils';
import {PermissionTargetEnum} from '../../../enums/PermissionTargetEnum';
import {InputButtonIconType} from '../../core/button/button.utils';
import {ConfirmService} from '../../../services/confirm.service';
import {NumberUtils} from '../../../utils/NumberUtils';
import {File} from '../../../dtos/file/File';
import {FileService} from '../../../services/rest/file.service';
import {FileUpdate} from '../../../dtos/file/FileUpdate';

@Component({
  selector: 'app-file-edit',
  templateUrl: './file-edit.component.html',
  styleUrls: ['./file-edit.component.scss']
})
export class FileEditComponent implements OnInit {
  InputButtonIconType = InputButtonIconType;

  REDIRECT_ON_ERROR = '/files';
  REDIRECT_ON_SUCCESS = '/files';

  file: File;

  form: FormGroup;

  userCanEdit: boolean = AuthUtils.canEdit(PermissionTargetEnum.FILES);

  constructor(private authService: AuthenticationService,
              private fileService: FileService,
              private messageService: MessageService,
              private formBuilder: FormBuilder,
              private activatedRoute: ActivatedRoute,
              private confirmService: ConfirmService,
              private router: Router) {
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      const id = params.id;
      if (!NumberUtils.isNumber(id)) {
        this.messageService.addErrorMessage("Kein gültiger Parameter in der URL!");
        console.log('Given parameter is not valid: ' + id);
        console.log('Redirect to ' + this.REDIRECT_ON_ERROR);
        this.router.navigate([this.REDIRECT_ON_ERROR]);
        return;
      }
      this.getFile(id);
    });
  }

  submitChanges() {
    if (this.form.valid) {
      const file: FileUpdate = {
        content: null,
        file: {
          id: this.form.controls.id.value,
          name: this.form.controls.name.value,
          description: this.form.controls.description.value
        }
      };
      this.fileService.updateFile(file).subscribe(() => {
        this.messageService.addSuccessMessage('Datei erfolgreich bearbeitet!');
      });
    }
  }

  onDeleteFile(file: File): void {
    this.confirmService.confirm(
      `Datei löschen`,
      `Wollen Sie die Datei ${file.name} wirklich löschen? <br>` +
      `Dieser Vorgang kann nicht rückgängig gemacht werden.`,
      () => {
        this.fileService.deleteFileById(file.id).toPromise().then(() => {
          this.messageService.addSuccessMessage(`Datei ${file.name} wurde erfolgreich gelöscht!`);
          console.log('Redirect to ' + this.REDIRECT_ON_SUCCESS);
          this.router.navigate([this.REDIRECT_ON_SUCCESS]);
        });
      });
  }

  private getFile(id: number) {
    this.fileService.getFile(id).subscribe((file: File) => {
      this.file = file;
      this.createForm(file);
    }, () => {
      console.log('No file found with id: ' + id);
      console.log('Redirect to ' + this.REDIRECT_ON_ERROR);
      this.router.navigate([this.REDIRECT_ON_ERROR]);
    });
  }

  private createForm(file: File) {
    this.form = this.formBuilder.group({
      id: [file.id],
      file: [file],
      name: [file.name, [Validators.required, Validators.minLength(3), Validators.maxLength(255)]],
      description: [file.description, [Validators.maxLength(255)]]
    });
    if (!this.userCanEdit) {
      this.form.disable();
    }
  }

}
